import React, {useContext, useEffect, useState} from "react";
import avatarImage from "../../assets/images/user_avatar.png";
import DeleteMessageRoom from "../../components/DeleteMessageRoom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCheckCircle, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {ALERT_MESSAGE_TYPE_SUCCESS, BACKEND_URL, MESSAGE_MY} from "../../const/Constants";
import MessageItem from "../../components/MessageItem";
import SOCKET from "../../const/socketNames";
import {useStore} from "../../store";
import {SocketContext} from "../../const/socket";
import {getQueryByParameter} from "../../const/axiosInstance";
import {observer} from "mobx-react-lite";
import FileUpload from "../../components/FileUpload";
import UserChat from "../../states/UserChat";
import AlertComponent from "../../utils/Alert";
import {Menu} from "@headlessui/react";
import User from "../../states/User";
import userAvatar from "../../assets/images/user_avatar.png"
import { langContext } from "../../App";
import { langChat } from "./chatTranslate";
import "./chat.css"
import "../../components/message.css"


const Chat = observer(() => {
    const lang = useContext(langContext);
    let showBySelected = langChat[lang]

        const [offset, setOffset] = useState(0)
        const [message, setMessage] = useState('')
        const [loading, setLoading] = useState(false)
        const [bigFile, setBigFile] = useState(false)
        const {userStore, chatStore, usersStore} = useStore();
        const socket = useContext(SocketContext)
        const [size, setSize] = useState(0)
        
        
        function scrollDown() {
            document.getElementById('messages').scrollTop = document.getElementById('messages').scrollHeight
        }


        useEffect(() => {
            usersStore.getUsers();
            scrollDown()
        },    
     [])

        

        const deleteChatRoom = async () => {
            if (window.confirm(showBySelected.tr_deleteMessages)) {
                const result = await UserChat.deleteMessageRoomById()
                socket.emit(SOCKET.DELETE_ALL_MESSAGES,  chatStore.user)
                if (result.status) {
                    window.location.reload()
                    AlertComponent(ALERT_MESSAGE_TYPE_SUCCESS, result.message)
                }
            }
        }


   
        async function moreData() {
            if (chatStore.username !== "ALL") {
                await chatStore.getMessages({ roomId: chatStore.getRoomId, offset: offset + 10 });
                setOffset(offset => offset + 10)
            } else if (chatStore.user === 'ALL') {
                setOffset(offset => offset + 10)
                await chatStore.getAllMessages(offset, 10)
    
            }
        }

        useEffect(function () {
            chatStore.setRoomId(getQueryByParameter('roomId'))
            chatStore.setUsername(getQueryByParameter('username'))
            if (chatStore.getRoomId) {
                socket.emit(SOCKET.JOIN_CONNECTED, userStore.username, true)
                socket.emit(SOCKET.CHAT_PRIVATE_JOIN_ROOM, {roomId: chatStore.getRoomId})
            }
            if (chatStore.username === 'Admin')
                chatStore.getAdminMessages()
            else
                chatStore.getMessages({roomId:chatStore.roomId})

            setTimeout(function () {
                scrollDown()
            }, 200)
            return () => {
                socket.off(SOCKET.CHAT_PRIVATE_JOIN_ROOM)
            }
        }, [])



        const sendMessage = () => {
            if (!message) {
                return
            }
            // chatStore.addMessage(message, MESSAGE_MY,
            //     new Date().toLocaleDateString('en',
            //         {day: '2-digit', month: '2-digit', year: '2-digit', hour: 'numeric', hour12: false, minute: 'numeric'}))
            socket.emit(SOCKET.CHAT_PRIVATE_GET_MESSAGE, {
                message: message.trim(),
                room_id: chatStore.getRoomId,
                role: userStore.role,
                sender_username: userStore.username,
                receiver_username: chatStore.username,
                size
            })
            setMessage('')

            setTimeout(function () {
                scrollDown()
            }, 0)
        }

        useEffect(() => {
            socket.on(SOCKET.GET_MY_MESSAGE_MYSELF, (message) => {
                chatStore.addMessage(message.message, MESSAGE_MY, new Date().toLocaleDateString('en', {
                    day: '2-digit', month: '2-digit', year: '2-digit', hour: 'numeric', hour12: false, minute: 'numeric'
                }), null, message._id)
                setTimeout(function () {
                    scrollDown()
                }, 0)
            })
            socket.on(SOCKET.CHAT_REFRESH_ROOMS, (data) => {
                chatStore.setIsRead()
                if(chatStore.user === 'ALL'){
                    chatStore.getAllMessages(0, chatStore.messageHistory.length)
                }else {
                    chatStore.getMessages({ roomId: chatStore.getRoomId,offset:0, limit:chatStore.messageHistory.length })
                }
            })
            return () => {
                socket.off(SOCKET.CHAT_REFRESH_ROOMS)
            }
        }, [])

        useEffect(() => {
            socket.on(SOCKET.CHAT_PRIVATE_SEND_MESSAGE_ADMIN, function (data) {
                chatStore.addMessage(data.message, data.type,
                    new Date().toLocaleDateString('en',
                        {
                            day: '2-digit',
                            month: '2-digit',
                            year: '2-digit',
                            hour: 'numeric',
                            hour12: false,
                            minute: 'numeric'
                        }))
                socket.emit(SOCKET.CHAT_VIEWED_ROOMS, {name: userStore.username, caller: data.username})


                setTimeout(function () {
                    scrollDown()
                }, 0)
            })
            socket.on(SOCKET.CHAT_PRIVATE_SEND_MESSAGE, function (data) {

                chatStore.addMessage(data.message, data.type,
                    new Date().toLocaleDateString('en',
                        {
                            day: '2-digit',
                            month: '2-digit',
                            year: '2-digit',
                            hour: 'numeric',
                            hour12: false,
                            minute: 'numeric'
                        }))
                socket.emit(SOCKET.CHAT_VIEWED_ROOMS, {name: userStore.username, caller: data.username})

                setTimeout(function () {
                    scrollDown()
                }, 0)
            })
            socket.on(SOCKET.UPDATE_MESSAGES, () => {
                if(chatStore.user === 'ALL'){
                    chatStore.getAllMessages(0, chatStore.messageHistory.length)
                }else {
                    chatStore.getMessages({ roomId: chatStore.getRoomId,offset:0, limit:chatStore.messageHistory.length })
                }
            })
            return () => {
                socket.off(SOCKET.CHAT_PRIVATE_SEND_MESSAGE_ADMIN)
                socket.off(SOCKET.CHAT_PRIVATE_GET_MESSAGE)
                socket.off(SOCKET.CHAT_PRIVATE_SEND_MESSAGE)
            }
        }, [socket])

    const deleteChatMessage = async (message) => {
        if (window.confirm(showBySelected.tr_deleteMessage)) {
            chatStore.deleteMessageById(message).then(res => { 
                socket.emit(SOCKET.DELETE_MESSAGE,  chatStore.user)
                chatStore.getMessages({roomId: chatStore.getRoomId})
            })
        }
    }

  


        return (
            <div className="main-newchat-div">
                <div className="new-page-chat-div">
                    <div className="new-chat-header">
                        <div className="new-chat-user-img-name-div">
                            <div>
                                <img
                                    src={usersStore.users.find(el => el.username === chatStore.user) ? usersStore.users.find(el => el.username === chatStore.user).image ? `${BACKEND_URL}${usersStore.users.find(el => el.username === chatStore.user).image}` : userAvatar : userAvatar}
                                    alt="avatar" className="new-chat-user-img"/>
                            </div>
                            <div>
                                <div>
                                    <span >{(usersStore.users.find(el => el.username === chatStore.user)?.name || usersStore.users.find(el => el.username === chatStore.user)?.last_name || usersStore.users.find(el => el.username === chatStore.user)?.father_name) ? usersStore.users.find(el => el.username === chatStore.user)?.name + ' ' + usersStore.users.find(el => el.username === chatStore.user)?.last_name + ' ' + usersStore.users.find(el => el.username === chatStore.user)?.father_name : chatStore.user === 'ALL' ? 'Իմ Նամակները' : chatStore.user}</span>
                                </div>
                                <span/>
                            </div>
                        </div>
                    <div>
                        <div className="new-chat-icons">
                            <img src="/deleteIcon.png"
                                        onClick={() => deleteChatRoom()}
                                        className="" />
                        </div>
                    </div>
                </div>
                <div className="new-msg-window">
                    <div id='messages'>
                        {chatStore.messages.length >= 10 && chatStore.messages.length > offset ? <button onClick={() => moreData()}>{showBySelected.tr_seeMore}</button> : ""}
                        {chatStore.messages.filter(el => el).map((message, index) => {
                            if (message.type === MESSAGE_MY) {
                                return (<div className="my-msg-div" key={index}>
                                            <div>
                                                <div>
                                                    {(chatStore.user === 'ALL') && (
                                                        <div>
                                                            {message.receiver_username}
                                                        </div>)}
                                                    <div>
                                                        <Menu as="div">
                                                            <div>
                                                                <Menu.Button>
                                                                    <span className="my-msg">
                                                                        <MessageItem message={message.message}/>
                                                                    </span>
                                                                </Menu.Button>
                                                            </div>
                                                            <Menu.Items>
                                                                <div>
                                                                    <Menu.Item>
                                                                        <button onClick={() => deleteChatMessage(message)} className="new-msg-delete-btn">
                                                                            <img src="/deleteIcon.png"/>
                                                                            {showBySelected.tr_delete}
                                                                        </button>
                                                                    </Menu.Item>
                                                                </div>
                                                            </Menu.Items>
                                                        </Menu>
                                                    </div>
                                                    <span className="time-viewed">
                                                        {message.created_at} {message.is_viewed ? (
                                                            <img src="/receiptviewed.png" />) : (
                                                            <img src="/receiptnotviewed.png" />)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>)
                            } else {
                                return (<div key={index}>
                                            <div style={{"width" :"318px"}}>
                                                <div>
                                                    {(chatStore.user === 'ALL') && (
                                                    <div>
                                                            {message.username}
                                                    </div>)}
                                                    <div >
                                                        <Menu as="div">
                                                            <div>
                                                                <Menu.Button>
                                                                    <span className="msg-me">
                                                                        <MessageItem message={message.message}/>
                                                                    </span>
                                                                </Menu.Button>
                                                            </div>
                                                            <Menu.Items>
                                                                <div>
                                                                    <Menu.Item>
                                                                        <button onClick={() => deleteChatMessage(message)} className="right-move">
                                                                            <img src="/deleteIcon.png"/>    
                                                                            {showBySelected.tr_delete}
                                                                        </button>
                                                                    </Menu.Item>
                                                                </div>
                                                            </Menu.Items>
                                                        </Menu>
                                                        <span className="me-time-viewd">
                                                            {message.created_at} 
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                    }
                                })}
                    </div>
                </div>
                <div >
                    <div className="new-send-div">
                        <div className="send-msg">
                                <FileUpload setField={setMessage} setSize={setSize} setLoading={setLoading} uploadFile={chatStore.uploadMessages} setBigFile={setBigFile} >
                                    <img src="/Iconfileupload.png" style={{"cursor":"pointer"}}/>
                                </FileUpload>
                            <input type="text"
                                value={message}
                                onKeyDown={(event) => event.key === 'Enter' && (event.ctrlKey || event.metaKey || event.shiftKey) ? (() =>{event.preventDefault(); sendMessage()})() : ''}
                                onChange={(event) => message.length < 4000 ? setMessage(event.target.value) : ""}
                                placeholder={bigFile? "Ֆայլը չպետք է գերազանցի 5GB-ը" : loading ? "Բեռնվում է..." : showBySelected.tr_correspondence}
                                className="text-send"/>
                        </div>
                        <button
                                type="button"
                                onClick={sendMessage}
                                className="send-btn-private">
                                    <img src="/sendmsg.png"/> 
                        </button>
                    </div>
                </div>
            </div>
        </div>  
        )
    }
)
export default Chat;