export const langMeetingChat = {
    "arm": {
        tr_correspondence: "Հաղորդագրություն"
    },
    "eng": {
        tr_correspondence: "correspondence"
    },
    "fr": {
        tr_correspondence: "Message"
    },
    "arb": {
        tr_correspondence: "رسالة"
    }
}