import React, { useContext, useEffect, useState } from "react";
import { ALERT_MESSAGE_TYPE_DANGER, ALERT_MESSAGE_TYPE_SUCCESS } from "../../const/Constants";
import AlertComponent from "../../utils/Alert";
import FileUpload from "../FileUpload";
import Loading from "../Loading"
import {useStore} from "../../store"
import DeleteMessageRoom from "../DeleteMessageRoom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import { langContext } from "../../App";
import { langUserForm } from "./userFormTranslate";
import {  BACKEND_URL } from "../../const/Constants";
import avatarImage from "../../assets/images/user_avatar.png";


const UserForm = (navbar) => {
    const lang = useContext(langContext);
    let showBySelected = langUserForm[lang]
    const [data, setData] = useState({
        name: "",
        last_name: "",
        father_name: "",
        tel_number:"",
        birth_place: "",
        work_place: "",
        staff: "",
        email: ""
    })
    const [loading, seTLoading] = useState(true)
    const [loadingImg, setLoadingImg]= useState(false)
    const {userStore} = useStore()
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        userStore.getCurrentUser().then(res => {
            setData({...data, ...userStore.userData})
            seTLoading(false)
        })

    }, [])

    const handleChange = (e) => {
        let { name, value } = e.target

        let fields = [
            'name',
            'last_name',
            'father_name',
            'tel_number',
            'email'
        ]
        if (fields.includes(name))
            value = value.trim()
        setData({ ...data, [name]: value })
        setDisabled(false)
    }

    const styleBackground = {
        "background-image": `url(${userStore.imagePath ? `${BACKEND_URL}${userStore.imagePath}`: avatarImage})`,
        "background-position": "center",
        "background-size": "cover"
    }

    const handleSubmit = async (e) => {
         e.preventDefault()
        const result = await userStore.changePersonalData(data)
         await userStore.getCurrentUser()
        const status = result.status ? ALERT_MESSAGE_TYPE_SUCCESS : ALERT_MESSAGE_TYPE_DANGER
        AlertComponent(status, result.message)
        setDisabled(true)
    }

    const deleteAvatar = async () => {
        const result = await userStore.deleteProfilePhoto()
        const status = result.status ? ALERT_MESSAGE_TYPE_SUCCESS: ALERT_MESSAGE_TYPE_DANGER
        userStore.getCurrentUser()
        AlertComponent(status, result.message)
    }

    const setImagePath = (path) => {
        userStore.setUserImage(path)
        userStore.getCurrentUser()
    }

    const { name, last_name, birth_place,father_name, tel_number, work_place, staff, email } = data

    return (
        <> {loading ? <Loading /> : (<form onSubmit={handleSubmit} className="">
            <div className="">
                <div className="flex-div">
                    <div style={{"width" : "50%"}}>
                        <div className="user-page-p">
                            <p className="user-page-p">{showBySelected.tr_name}</p>                            
                            <input type="text" maxLength={20} name="name" id="name"
                                   className="user-page-input "
                                   placeholder={showBySelected.tr_name} value={name} onChange={handleChange} />
                        </div>
                        <div className="user-page-p">
                            <p className="user-page-p">{showBySelected.tr_surname}</p>
                            <input type="text" maxLength={20} name="last_name" id="lastname"
                                   className="user-page-input"
                                   placeholder={showBySelected.tr_surname} value={last_name} onChange={handleChange} />
                        </div>
                        <div className="user-page-p mob-patronymic">
                            <p className="">{showBySelected.tr_patronymic}</p>
                            <input type="text" maxLength={20} name="father_name" id="father_name"
                                   className="user-page-input"
                                   placeholder={showBySelected.tr_patronymic} value={father_name} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="user-img-div" style={{"width" : "50%"}}>
                        <div className=''>
                                <p className="user-page-p" style={{"margin-top":"5px"}}>{showBySelected.tr_image}</p>
                            <div className="upload-back" style={styleBackground}>
                                {/* <img src={userStore.imagePath ? `${BACKEND_URL}${userStore.imagePath}` : avatarImage} className="upload-photo"/> */}
                            </div>
                            
                            <div style={{textAlign: "center"}}>
                                <FileUpload navbar={navbar} setField={setImagePath} setLoading = {setLoadingImg} uploadFile={userStore.uploadProfilePhoto}><div className="insideload-btn">{showBySelected.tr_insertPhoto}</div></FileUpload>
                                <button type="button" disabled={!userStore.imagePath}
                                    className="delete-btn" onClick={deleteAvatar}>{showBySelected.tr_removePhoto}
                                </button>
                            </div>
                        </div>
                    </div>                 
                </div>

                <div style={{"margin":"0 16px 0 16px"}}>
                    <p className="user-page-p" style={{"margin-top": window.innerWidth < 600 ? "10px" : "0px"}}>{showBySelected.tr_position}</p>
                    <input type="text" maxLength={30} name="staff" id="staff"
                           className="user-page-input" style={{"width":"100%"}}
                           placeholder={showBySelected.tr_position} value={staff} onChange={handleChange} />
                </div>
            </div>
            <div style={{"width":"100%"}}>
                <div className="inputs-flex">
                    <div style={{"width":"50%"}}>
                        <p className="user-page-p">{showBySelected.tr_residenc}</p>
                        <input type="text" maxLength={20} name="birth_place" id="birth_place"
                               className="user-page-input"
                               placeholder={showBySelected.tr_residenc} value={birth_place} onChange={handleChange} />
                    </div>
                    <div style={{"width":"50%","margin":"0 16px 0 16px"}}>
                        <p className="user-page-p">{showBySelected.tr_workplace}</p>
                        <input type="text" maxLength={30} name="work_place" id="workplace"
                               className="user-page-input"
                               placeholder={showBySelected.tr_workplace} value={work_place} onChange={handleChange} />
                    </div>
                </div>
            </div>
            <div style={{"width":"100%"}}>
                <div className="inputs-flex" >
                    <div style={{"width":"50%"}}>
                        <p className="user-page-p">{showBySelected.tr_telephone}</p>
                        <input type="text" maxLength={20} name="tel_number" id="tel_number"
                               className="user-page-input"
                               placeholder={showBySelected.tr_telephone} value={tel_number} onChange={handleChange} />
                    </div>            
    
            
                    <div style={{"width":"50%","margin":"0 16px 0 16px"}}>
                        <p className="user-page-p">{showBySelected.tr_email}</p>
                        <input type="email" maxLength={30} name="email" id="email"
                               className="user-page-input"
                               placeholder={showBySelected.tr_email} value={email} onChange={handleChange} />
                    </div>
                </div>
            </div>

           

            <button type="submit" className="submit-btn">{showBySelected.tr_confirm}</button>
          
        </form>)}

        </>
    )
}

export default UserForm