import React, {useContext, useState} from "react";
import { axiosInstance, getQueryByParameter } from "../const/axiosInstance";
import { ALERT_MESSAGE_TYPE_DANGER } from "../const/Constants";
import AlertComponent from "../utils/Alert";
import {useStore} from "../store"
import { NotificationContainer } from 'react-notifications';
import {popUp} from "../utils/helpers";
import { langContext } from "../App";
import { langMeetingGuest } from "./meetingGuestTranslate";
import "./login.css"


const MeetingGuest = () => {
    const lang = useContext(langContext);
    let showBySelected = langMeetingGuest[lang]

    const [username, setUsername] = useState("")
    const [checked, setChecked] = useState(false)
    const {userStore} = useStore()
    
    const secret = getQueryByParameter("secret")

    
    const handleSubmit = async (e) => {
        e.preventDefault()
        const roomId = getQueryByParameter("room_id")
        let singupResult = null
        if(!checked) {
             const data = { username, secret, roomId }
             singupResult = await userStore.guestUserSignup({ username, secret, roomId })
            if(!singupResult.status) {
                AlertComponent(ALERT_MESSAGE_TYPE_DANGER, singupResult.message)
                return 
            }
        }else{
            singupResult = await axiosInstance.post("/auth/blockedCheck", { username, secret, roomId })
            if(!singupResult.data.status) {
                AlertComponent(ALERT_MESSAGE_TYPE_DANGER, singupResult.data.message)
                return 
            }
        }

        const singinResult = await userStore.login({username, password: singupResult.data})
        if(!singinResult.status) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, showBySelected.tr_notRegistered)
            return 
        }
        userStore.setUsername(username)

        if(!roomId) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, showBySelected.tr_conferanceNotFound)
        }else {
            popUp(`/video-room/${roomId}?purpose=Group`)
        }

    }

    return (
        <div className="login-page">
            <div className="background-div">
                <div className="info-div" style={{"height": "400px"}}>
                    <h1 className="fontstyle" style={{"font-weight": "500", "font-size": "24px", "margin": "40px 0 15px 0"}}>{showBySelected.tr_connectToVideo}</h1>
                    <h3 className="fontstyle" style={{"font-weight": "600", "font-size": "32px"}}>VKS</h3>
                    <h3 className="fontstyle" style={{"font-weight": "600", "font-size": "16px", "margin-bottom":"35px"}}>{showBySelected.tr_confSystem}</h3>
                    <form onSubmit={handleSubmit}>
                        <p className="fontstyle" style={{"text-align": "left", "margin-left":"32px"}}>{showBySelected.tr_userName} </p>
                        <div className="info-input-div" style={{"margin-left":"32px"}}>
                            <input type="text" name="username" maxLength={30} placeholder={showBySelected.tr_userName} value={username} onChange={(e) => setUsername(e.target.value)}
                                   className="info-input"/>
                        </div>
                        <div className='info-center'>
                            <button type={"submit"}
                                        className="fontstyle">
                                    {showBySelected.tr_joinTheMeting}
                            </button>
                        </div>
                       
                        <NotificationContainer/>
                    </form>
                </div>
            </div>
        </div>)

}

export default MeetingGuest
