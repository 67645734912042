import React from 'react'
import { langObj } from './langObj'

export default function LangModal({change, closeModal}) {
  return (
    <div className='lang-choose'>
         <div className='lang-items' onClick={() => {change("arm"); closeModal()}}>
             <img className="lang-image" src={`/armlang.png`} />
                 {langObj.arm}
         </div>
         <div className='lang-items' onClick={() => {change("eng"); closeModal()}}>
             <img className="lang-image" src={`/englang.png`} />
                 {langObj.eng}
         </div>
         <div className='lang-items'  onClick={() => {change("fr"); closeModal()}}>
             <img className="lang-image" src={`/frlang.png`} />
                 {langObj.fr}
         </div>
         <div className='lang-items' onClick={() => {change("arb"); closeModal()}}>
             <img className="lang-image" src={`/arblang.png`}  />
                 {langObj.arb}
         </div>
    </div>
  )
}
