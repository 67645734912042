import {PeerActionType} from "./peerTypes.ts";
import {Dispatch} from "redux";
import {DataType, PeerConnection} from "../../helpers/peer.ts";
import {message} from "antd";
import {addConnectionList, removeConnectionList} from "../connection/connectionActions.ts";
import download from "js-file-download";

export const startPeerSession = (id: string) => ({
    type: PeerActionType.PEER_SESSION_START, id
})

export const stopPeerSession = () => ({
    type: PeerActionType.PEER_SESSION_STOP,
})
export const setLoading = (loading: boolean) => ({
    type: PeerActionType.PEER_LOADING, loading
})

export const startPeer: () => (dispatch: Dispatch) => Promise<void>
    = () => (async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const id = await PeerConnection.startPeerSession()
        PeerConnection.onIncomingConnection((conn) => {
            const peerId = conn.peer
            message.info("Կապի հաստատում։ " + peerId)
            dispatch(addConnectionList(peerId))
            PeerConnection.onConnectionDisconnected(peerId, () => {
                message.info("Կապի ավարտ: " + peerId)
                dispatch(removeConnectionList(peerId))
            })
            PeerConnection.onConnectionReceiveData(peerId, (file) => {
                message.info(file.fileName + " ֆայլը ստացվեց")
                if (file.dataType === DataType.FILE) {
                    download(file.file || '', file.fileName || "fileName", file.fileType)
                }
            })
        })
        dispatch(startPeerSession(id))
        dispatch(setLoading(false))
    } catch (err) {
        dispatch(setLoading(false))
    }
})


