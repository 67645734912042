export const langProfile = {
    "arm": {
        tr_setting: "Կարգավորումներ",
        tr_profile: "Պրոֆիլ",
        tr_password: "Գաղտնաբառ",
        tr_audioVideo: "Աուդիո և վիդեո",
        tr_language: "Լեզու"
    },
    "eng": {
        tr_setting: "Settings",
        tr_profile: "Profile",
        tr_password: "Password",
        tr_audioVideo: "Audio and video",
        tr_language: "Language"
    },
    "fr": {
        tr_setting: "Paramètres",
        tr_profile: "Profil",
        tr_password: "Mot de passe",
        tr_audioVideo: "Audio et vidéo",
        tr_language: "Langue"
    },
    "arb": {
        tr_setting: "إعدادات",
        tr_profile: "حساب تعريفي",
        tr_password: "كلمة المرور",
        tr_audioVideo: "صوت و فيديو",
        tr_language: "لغة"
    },
}