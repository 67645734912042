import React, {useContext, useState} from "react";
import { langContext } from "../../App";
import { langApproveOrCancel } from "./approveOrCancelTranslate";
import "./cancel.css"
import "./delete.css"


export const ApproveOrCancel = ({approveHandler, cancelHandler, title, reason_need = true}) => {
    const lang = useContext(langContext);
    let showBySelected = langApproveOrCancel[lang]

    const [reasonMessage, setReasonMessage] = useState('')

    return (
        <div className="cnc-main">
            {
                reason_need && <>
                    <label htmlFor="reason" className="cnc-header">{title}</label>
                    <div className="cnc-input-div">
                        <p >{showBySelected.tr_youWantCancel}</p>
                        <input id="reason" placeholder={showBySelected.tr_cause}
                           className="cnc-input" value={reasonMessage}
                           onChange={(e) => setReasonMessage(e.target.value)}/>
                    </div></>
            }
            {
            !reason_need && <div>
                <p className='dlt-header'>{showBySelected.tr_delete}</p>
                <p className="dlt-question">{showBySelected.tr_youSure}</p>
            </div>
            }
            <div className="cnc-btns">
                <button className="cnc-accept" onClick={() => cancelHandler()}>{showBySelected.tr_refuse}</button>
                <button className="cnc-reject" onClick={() => approveHandler(reasonMessage)}>
                    {showBySelected.tr_confirm}
                </button>
                
            </div>

        </div>
    )
}