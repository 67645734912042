export const langCreateRoom = {
    "arm": {
        tr_createNewMeeting: "Ստեղծել նոր հանդիպում",
        tr_meetingTitle: "Հանդիպման անունը",
        tr_theNumberOfParticipants: "Հանդիպման մասնակիցների քանակը",
        tr_selectUsers: "Ընտրել օգտվողներին",
        tr_selectTheTime: "Ընտրեք ձեր հանդիպման ժամը",
        tr_accept: "Ընդունել",
        tr_participants: "Մասնակիցներ",
        tr_dayAndTime: "Օր և ժամ",

    },
    "eng": {
        tr_createNewMeeting: "Create a new meeting",
        tr_meetingTitle: "Meeting title",
        tr_theNumberOfParticipants: "The number of participants to the meeting",
        tr_selectUsers: "Select users",
        tr_selectTheTime: "Select the time of your meeting",
        tr_accept: "Accept",
        tr_participants: "Participants",
        tr_dayAndTime: "Day and time",

    },
    "fr": {
        tr_createNewMeeting: "Créer une nouvelle réunion",
        tr_meetingTitle: "Titre de la réunion",
        tr_theNumberOfParticipants: "Nombre de participants à la réunion",
        tr_selectUsers: "Sélectionnez les utilisateurs",
        tr_selectTheTime: "Sélectionnez l’heure de votre réunion.",
        tr_accept: "Accepter",
        tr_participants: "participants",
        tr_dayAndTime: "Jour et heure",

    },
    "arb": {
        tr_createNewMeeting: "إنشاء اجتماع جديد",
        tr_meetingTitle: "اسم الاجتماع",
        tr_theNumberOfParticipants: "عدد المشاركين في الاجتماع",
        tr_selectUsers: "اختيار المستخدمين",
        tr_selectTheTime: "الرجاء اختيار وقت اجتماعك",
        tr_accept: "قبول",
        tr_participants: "الحاضرين",
        tr_dayAndTime: "اليوم والوقت",

    }
}