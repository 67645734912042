import Peer from "peerjs";
import {PEER_HOSTNAME, PEER_PORT, PEER_SECURE, PEER_URL} from "../const/Constants";
import {socket} from "../const/socket";
import SOCKET from "../const/socketNames";
import User from "../states/User";
import VideoRoom from "../states/VideoRoom";
import avatarImage from "../assets/images/user_avatar.png";

export const peers = {}

class PEERConnection {
    myPeer
    myId = ""
    socket = socket
    stream
    userDetails = {}
    ROOM_ID

    constructor({userDetails, roomId}) {
        this.userDetails = userDetails
        this.ROOM_ID = roomId
        this.myPeer = initializePeerConnection()
        this.initializePeersEvents()
        this.initializeSocketEvents()
    }

    initializeSocketEvents = () => {
        this.socket.on(SOCKET.USER_EXIT_FROM_MEETING, (peerId) => {
            peers[peerId] && peers[peerId].close();
            this.removeVideo(peerId);
        })
        this.socket.on(SOCKET.MEETING_FINISHED, () => {
            this.destroyConnection()
            window.localStorage.removeItem("existRoom")
            window.close()
        })
        this.socket.on(SOCKET.MEETING_REMOVE_DISCONNECTED_USER_VIDEO, (peerId, username) => {
            if (User.username === username) {
                this.destroyConnection()
                window.localStorage.removeItem("existRoom")
                window.close()
            } else {
                peers[peerId] && peers[peerId].close();
                this.removeVideo(peerId);
            }
        })
    }

    initializePeersEvents = () => {
        this.myPeer.on('open', async (id) => {
            this.myId = id;
            const {username} = this.userDetails
            this.socket.emit(SOCKET.JOIN_MEETING_ROOM, this.ROOM_ID, this.myId, username);
            await this.setNavigateToStream();
        });
        this.myPeer.on('error', (err) => {
            console.log('peer connection error', err);
            this.myPeer.reconnect();
        })
    }

    setNavigateToStream = async () => {
        const video = await this.getVideoDevices()
        this.stream = await this.getVideoAndAudioStream(video)
        if (this.stream) {
            socket.emit(SOCKET.MEETING_CONNECTION_REQUEST, this.ROOM_ID, User.username, !!this.stream.getVideoTracks()[0].canvas, this.myId);
            this.showLocalPreview({stream: this.stream, video})
            this.setPeersListeners(this.stream);
            this.newUserConnection();
        }
    }

    getVideoAndAudioStream = async (video = false) => {
        const myNavigator = navigator.mediaDevices.getUserMedia || navigator.mediaDevices.webkitGetUserMedia || navigator.mediaDevices.mozGetUserMedia || navigator.mediaDevices.msGetUserMedia;
        try {
            const stream = await myNavigator({
                video: video ? {
                    frameRate: 12,
                    width: {exact: 640},
                    height: {exact: 480}
                } : false,
                audio: true,
            });

            if (!stream.getVideoTracks().length) {
                const audioTrack = stream.getAudioTracks()[0]
                const videoStream = this.createBlankVideoTrack()
                videoStream.addTrack(audioTrack)
                return videoStream
            }
            return stream
        } catch (e) {
            const stream = this.createBlankVideoTrack()
            const audioContext = new AudioContext({sinkId: "none"});
            const streamDestination = audioContext.createMediaStreamDestination();
            const audioTrack = streamDestination.stream.getAudioTracks()[0]
            stream.addTrack(audioTrack)
            return stream
        }
    }
    newUserConnection = () => {
        this.socket.on(SOCKET.MEETING_NEW_USER_CONNECT, (userData) => {
            if (this.myId !== userData.peerId) {
                console.log('New User Connected', userData);
                this.connectToNewUser(userData, this.stream);
            }
        });
    }

    connectToNewUser(userData, stream) {
        const {peerId, userName, isCanvas} = userData;
        const username = User.userData.name || User.userData.last_name ? `${User.userData.name} ${User.userData.last_name}` : User.username
            const call = this.myPeer.call(peerId, stream, {
            metadata: {
                id: this.myId,
                userName: username,
                isCanvas: !!stream.getVideoTracks()[0].canvas
            }
        });
        call.on('stream', (userVideoStream) => {
            this.addStream({id: peerId, stream: userVideoStream, username: userName, isCanvas});
        });
        call.on('close', () => {
            console.log('closing new user', peerId);
            this.removeVideo(peerId);
        });
        call.on('error', () => {
            console.log('peer error ------')
            this.removeVideo(peerId);
        })
        peers[peerId] = call;
    }

    createBlankVideoTrack(opts = {}) {
        const {width = 1920, height = 1080} = opts
        const canvas = Object.assign(document.createElement("canvas"), {
            width,
            height,
        })
        let defImg = new Image(width, height);
        defImg.src = avatarImage;
        defImg.onload = function () {
            canvas.getContext("2d").drawImage(defImg, width / 2.8, height / 4);
        };
        return canvas.captureStream()
    }

    getVideoDevices = async () => {
        const videoDevices = []
        const devices = await navigator.mediaDevices.enumerateDevices()
        devices.forEach(device => {
            if (device.kind === 'videoinput') {
                videoDevices.push(device)
            }
        })
        return !!videoDevices[0]?.deviceId
    }
    showLocalPreview = ({stream, id, video = false}) => {
        const checkVideoContainer = document.getElementById(`${id}-video`)
        if (checkVideoContainer) {
            checkVideoContainer.srcObject = stream
            return
        }
        const videosContainer = document.getElementById("video-grid")
        const videoContainer = document.createElement("div")
        const videoElement = document.createElement("video")
        const videoUsername = document.createElement("p")
        const videoFullSize = document.createElement("span")
        videoUsername.classList.add("video__main-username")
        videoFullSize.classList.add("video__main-fullsize")
        videoContainer.classList.add("video__main-content")
        videoFullSize.addEventListener("click", onHandleDoubleClick)
        videoContainer.id = this.myId
        videoElement.id = `${this.myId}-video`
        videoElement.autoplay = true
        videoElement.muted = true
        videoElement.srcObject = stream
        videoUsername.textContent = User.userData.name || User.userData.last_name ? `${User.userData.name} ${User.userData.last_name}` : User.username
        videoFullSize.textContent = "fullsize"
        videoElement.classList.add("video__content");
        videoElement.onloadedmetadata = () => {
            videoElement.play().then(r => videoElement.addEventListener("click", onHandleClick))
        }
        videoContainer.append(videoElement)
        videoContainer.append(videoUsername)
        videoContainer.append(videoFullSize)
        videosContainer.append(videoContainer)
    }

    setPeersListeners = (stream) => {
        this.myPeer.on('call', (call) => {
            call.answer(stream);
            call.on('stream', (userVideoStream) => {
                this.addStream({
                    id: call.metadata.id,
                    stream: userVideoStream,
                    username: call.metadata.userName,
                    isCanvas: call.metadata.isCanvas
                });
            });
            call.on('close', () => {
                this.removeVideo(call.metadata.id);
            });
            call.on('error', () => {
                console.log('peer error ------');
                this.removeVideo(call.metadata.id);
            });
            peers[call.metadata.id] = call;
        });
    }

    removeVideo = (id) => {
        const video = document.getElementById(id);
        if (video) {
            VideoRoom.participants_count--
            video.remove();
        }
    }
    destroyConnection = () => {
        const myMediaTracks = this.stream?.getTracks();
        myMediaTracks?.forEach((track: any) => {
            track.stop();
        })
        this.myPeer?.destroy();
    }

    addStream = ({id, stream, username, isCanvas}) => {
        const checkVideoContainer = document.getElementById(id)
        if (checkVideoContainer) {
            return
        }
        const videosLength = document.querySelectorAll("video").length
        const videoContainer = document.createElement("div");
        const videoElement = document.createElement("video");
        const videoUsername = document.createElement("p")
        const videoFullSize = document.createElement("span")
        videoUsername.classList.add("video__main-username")
        videoFullSize.classList.add("video__main-fullsize")
        videoFullSize.textContent = "fullsize"
        videoFullSize.addEventListener("click", onHandleDoubleClick)
        videoUsername.textContent = username
        videoContainer.id = id;
        videoElement.id = `${id}-video`
        videoElement.srcObject = stream;
        videoElement.addEventListener("click", onHandleClick)
        if (isCanvas) {
            const image = document.createElement("img")
            image.src = avatarImage
            image.classList.add("video__content-image")
            const audio = document.createElement("audio")
            audio.srcObject = stream
            audio.play()
            videoContainer.append(audio)
            videoContainer.append(image)
        }
        videoElement.volume = 0

        videoElement.muted = this.myId === id;
        VideoRoom.participants_count++
        videoElement.onloadedmetadata = () => {
            videoElement.play().then(
                videoElement.volume = 0.7
            ).catch(e => {
                console.log(e)
            });
        };

        videoContainer.appendChild(videoElement);
        videoContainer.append(videoUsername)
        videoContainer.append(videoFullSize)

        if (videosLength > 1) {
            const videosContainer = document.getElementById("video-scroll")
            videoContainer.classList.add("video__main-scroll");
            videoElement.classList.add("video__scroll");
            videosContainer.appendChild(videoContainer);
        } else {
            const videosContainer = document.getElementById("video-grid")
            videoContainer.classList.add("video__main-content");
            videoElement.classList.add("video__content");
            videosContainer.appendChild(videoContainer);
        }
    };

    reInitializeStream = async (video, audio, type = 'userMedia') => {
        const videoControl = await this.getVideoDevices()

        const media = type === 'userMedia' ? this.getVideoAndAudioStream(videoControl) : navigator.mediaDevices.getDisplayMedia({
            cursor: true,
        });
        return new Promise((resolve) => {
            media.then((stream) => {
                if (type === 'displayMedia') {
                    this.toggleVideoTrack({audio, video});
                    this.listenToEndStream(stream, {video, audio});
                }else {
                    const videoTrack = stream.getVideoTracks();
                    const oldVideoTrack = this.stream.getVideoTracks()
                    this.stream.removeTrack(oldVideoTrack[0])
                    this.stream.addTrack(videoTrack[0])
                }
                this.showLocalPreview({stream, id: this.myId})
                replaceStream(stream);
                resolve(true);
            }).catch((e) => {
                console.log(e.message)
            });
        });
    }

    listenToEndStream = (stream, status) => {
        const videoTrack = stream.getVideoTracks();
        const oldVideoTrack = this.stream.getVideoTracks()
        this.stream.removeTrack(oldVideoTrack[0])
        this.stream.addTrack(videoTrack[0])
        if (videoTrack[0]) {
            videoTrack[0].onended = () => {
                this.reInitializeStream(status.video, status.audio, 'userMedia');
                this.userDetails.updateInstance('displayStream', false);
                this.toggleVideoTrack(status);
            }
        }
    };

    toggleVideoTrack = (status) => {
        const myVideo = this.getMyVideo();
        if (myVideo && !status.video) this.stream?.getVideoTracks().forEach((track) => {
            if (track.kind === 'video') {
                track.stop();
            }
        });
        else if (myVideo) {
            this.reInitializeStream(status.video, status.audio);
        }
    }

    getMyVideo = (id = this.myId) => {
        return document.getElementById(`${id}-video`);
    }


    toggleCamera = (isDisabled) => {
        this.stream.getVideoTracks()[0].enabled = !isDisabled;
    };

    toggleMic = (isMuted) => {
        this.stream.getAudioTracks()[0].enabled = !isMuted;
    };
}

export default PEERConnection

const onHandleClick = (e) => {
    if (!document.fullscreenElement) {
        let videoElement = e.target;
        let toGrid = false;
        if (e.target.localName === 'video')
            videoElement = e.target.parentElement
        document.querySelectorAll("#video-scroll>div").forEach(el => {
            if (el.id === videoElement.id) {
                toGrid = true;
                el.remove()
            }
        })
        if (toGrid) {
            videoElement.style.width = '700px';
            videoElement.style.height = '500px';
            videoElement.style.minWidth = '700px';
            videoElement.getElementsByTagName('video')[0].style.width = '700px';
            videoElement.getElementsByTagName('video')[0].style.height = '500px';
            document.querySelector("#video-grid").append(videoElement)
        } else {
            videoElement.style.width = '300px';
            videoElement.style.height = '200px';
            videoElement.style.minWidth = '300px';
            videoElement.getElementsByTagName('video')[0].style.width = '300px';
            videoElement.getElementsByTagName('video')[0].style.height = '200px';
            document.querySelector("#video-scroll").append(videoElement)
        }
    } else {
        document.fullscreenElement.pause()
        document.fullscreenElement.webkitExitFullscreen()
    }

}

const onHandleDoubleClick = (e) => {
    if (e.target.previousSibling.previousSibling.requestFullscreen) {
        e.target.previousSibling.previousSibling.requestFullscreen();
    } else if (e.target.previousSibling.previousSibling.mozRequestFullScreen) {
        e.target.previousSibling.previousSibling.mozRequestFullScreen();
    } else if (e.target.previousSibling.previousSibling.webkitRequestFullscreen) {
        e.target.previousSibling.previousSibling.webkitRequestFullscreen();
    } else if (e.target.previousSibling.previousSibling.msRequestFullscreen) {
        e.target.previousSibling.previousSibling.msRequestFullscreen();
    }

    document.querySelector('video').controls = false
}
const initializePeerConnection = () => {
    return new Peer(undefined, {
        host: PEER_HOSTNAME, port: PEER_PORT, secure: PEER_SECURE, path: PEER_URL,
        // config: {
        //     'iceServers': [
        //         {url: 'stun:stun1.l.google.com:19302'}
        //     ]
        // }
    });
}

const replaceStream = (mediaStream) => {
    Object.values(peers).map((peer) => {
        peer.peerConnection?.getSenders().map((sender) => {
            // if (sender.track.kind === "audio") {
            //     if (mediaStream.getAudioTracks().length > 0) {
            //         sender.replaceTrack(mediaStream.getAudioTracks()[0]);
            //     }
            // }
            if (sender.track.kind === "video") {
                if (mediaStream.getVideoTracks().length > 0) {
                    sender.replaceTrack(mediaStream.getVideoTracks()[0]);
                }
            }
        });
    })
}

