import { ALERT_MESSAGE_TYPE_DANGER, ALERT_MESSAGE_TYPE_SUCCESS } from "../const/Constants"

import { NotificationManager} from 'react-notifications';

const AlertComponent = (type, message) => {
        switch (type) {
            case ALERT_MESSAGE_TYPE_SUCCESS:
                NotificationManager.success('Հաջողված է', message);
                break;
            case ALERT_MESSAGE_TYPE_DANGER:
                NotificationManager.error('Սխալմունք', message, 5000);
                break;
            default:
                NotificationManager.info("Please contact with Admin");
                break;
        }
}

export default AlertComponent