import React, { useState } from 'react'
import LangModal from './LangModal'
import { langObj } from './langObj'

export default function Lang({value, change}) {
const [openModal, setOpenModal] = useState(false)

const closeModal = () => {
    setOpenModal(false)
}
const openCloseModal = (e) => setOpenModal(!openModal)
  return (
    <div>
        <div className="lang-ordinary" onClick={openCloseModal}>
            <img  src={`/${value}lang.png`} alt={langObj[value]} />
            <p>{langObj[value]}</p>
        </div>
        {openModal && <LangModal change={change} closeModal={closeModal} />}
    </div>
  )
}
