export const langUserModal = {
    "arm": {
        tr_settings: "Կարգավորումներ",
        tr_logOut: "Ելք",
        tr_help: "Օգնություն",
    },
    "eng": {
        tr_settings: "Settings",
        tr_logOut: "Log out",
        tr_help: "Help",
    },
    "fr": {
        tr_settings: "Paramètres",
        tr_logOut: "Sortie",
        tr_help: "Aide",
    },
    "arb": {
        tr_settings: "إعدادات",
        tr_logOut: "مخرج",
        tr_help: "المساعدة",
    },
}