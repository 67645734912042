import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { langContext } from '../../App'

export default function LangSection({change}) {
    let language={
        "arm": {
            tr_chooseLang: "Ընտրել լեզուն"
        },
        "eng": {
            tr_chooseLang: "Select the language"
        },
        "fr": {
            tr_chooseLang: ""
        },
        "arb": {
            tr_chooseLang: ""
        },
    }


    const choosed = useContext(langContext)
    const[lang,setLang] = useState(choosed)

    useEffect(() => change(lang), [lang])
  return (
    <div className='width100 height100'>
        <div className='lang-div'>
        <p className='user-page-p' style={{"margin-bottom": "10px"}}>{language[lang].tr_chooseLang}</p>
        <div className='flexizeing' onClick={() => setLang("arm")}>
            {lang === "arm" ? <img src='chackedradio.png' className='lang-img' /> : <img src='radio.png' className='lang-img' />}
            <div className={ lang === 'arm' ? "choosed-lang" : "lang-item"}>
                <p>AM</p>
                <img src='armlang.png' className='lang-img-mr'/>
                <p>Հայերեն</p>
            </div>
        </div>
        <div className='flexizeing' onClick={() => setLang("eng")}>
            {lang === "eng" ? <img src='chackedradio.png' className='lang-img' /> : <img src='radio.png' className='lang-img'/>}
            <div className={ lang === "eng" ? "choosed-lang" : "lang-item"}>
                <p>UK</p>
                <img src='englang.png' className='lang-img-mr'/>
                <p>English</p>
            </div>
        </div>
        <div className='flexizeing' onClick={() => setLang("fr")}>
            {lang === "fr" ? <img src='chackedradio.png' className='lang-img'/> : <img src='radio.png' className='lang-img'/>}
            <div className={ lang === "fr" ? " choosed-lang" : "lang-item"}>
                <p>FR</p>
                <img src='frlang.png' className='lang-img-mr'/>
                <p>Français</p>
            </div>
        </div>
        <div className='flexizeing' onClick={() => setLang("arb")}>
            {lang === "arb" ? <img src='chackedradio.png' className='lang-img'/> : <img src='radio.png' className='lang-img'/>}
            <div className={ lang === "arb" ? "choosed-lang " : "lang-item"}>
                <p>SA</p>
                <img src='arblang.png' className='lang-img-mr'/>
                <p>العربية</p>
            </div>
        </div>
        </div>
    </div>
  )
}
