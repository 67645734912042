import {  useContext, useEffect, useState } from "react";
import CreateRoom from "../components/modal/CreateRoom";
import Modal from "../components/modal/CustomModal"
import avatarImage from "../assets/images/user_avatar.png";
import customerImage from "../assets/images/customer-service.png";
import LogOutSvg from "../components/svg/LogOutSvg";
import UpcomingMeetingList from "./UpcomingMeetingsList";
import MeetingsList from "./MeetingsList";
import { useNavigate } from "react-router-dom";
import Profile from "./Profile";
import NewMessage from "../components/modal/NewMessage";
import {  BACKEND_URL } from "../const/Constants";
import { socket } from "../const/socket";
import SOCKET from "../const/socketNames";
import { useStore } from "../store"
import {observer} from "mobx-react-lite";
import {popUp} from "../utils/helpers";
import { axiosInstance } from "../const/axiosInstance";
import { langContext } from "../App";
import { langGeneral } from "./generalTranslate";
import "../pages/homepage.css"

const General = observer((props) => {
    const lang = useContext(langContext);
    let showBySelected = langGeneral[lang]

    const { userStore, chatStore } = useStore()
    const [requestedDate,setRequestedDate] = useState(0)
    


    const navigate = useNavigate()



    function closeModal() {
        props.setIsOpen(false);
    }


    useEffect(() => {
        chatStore.getAdminUnreadMessages()
    }, [])


    useEffect(() => {

        socket.on(SOCKET.CHAT_ROOM_INFO_ADMIN, async (room) => {
            if (room.type === 'Admin')
                popUp(`/chat?username=Admin&roomId=${room.id}`)
        })

        socket.on(SOCKET.CHAT_ROOM_INFO_NEW_TAB, async (room) => {
            if (room.type === 'blank')
                popUp(`/chat?username=${room.username}&roomId=${room.id}`)
        })

        return () => {
            socket.off(SOCKET.CHAT_ROOM_INFO_ADMIN)
            socket.off(SOCKET.CHAT_ROOM_INFO)
        }
    }, [])

    useEffect(() => {
       const date = async () => {
                const date = await axiosInstance.get("/devices/time")
                setRequestedDate(Date.now() - new Date(date.data.data))
             }
        date()
        localStorage.setItem("user", userStore.username)
    }, [])



    useEffect(() => {
        socket.on(SOCKET.CHAT_UNVIEWED_ROOMS, (data) => {
            if (data.role === "Admin") {
                chatStore.setAdminChat(true)
            }
        })
        socket.on(SOCKET.CHAT_REFRESH_ROOMS, (data) => {
            if (data.username === "Admin"){
                chatStore.setAdminChat(false)
            }
        })

        return () => {
            socket.off(SOCKET.CHAT_UNVIEWED_ROOMS)
            socket.off(SOCKET.CHAT_REFRESH_ROOMS)
        }

    }, [])

    function makeCon() {
        props.openModal("create")
    }

    const style = {
        display: window.innerWidth < 600 ? "block" : "flex"
    }

    return (
        <div className="inventions-mob">
            {
                props.navbar === "profile" ? <Profile setNavbar={props.setNavbar} change={props.change} navbar={props.navbar}/> :
                        (<div style={style}>
                             <div className='inventions-my'>
                                <MeetingsList title={showBySelected.tr_myInvitations} searchInput={props.searchInput} setSearchInput={props.setSearchInput} setGroupChatOpened={props.setGroupChatOpened} groupChatOpened={props.groupChatOpened} makeCon={makeCon} requestedDate={requestedDate}/>
                            </div>
                            <div className='inventions-me'>
                                <UpcomingMeetingList title={showBySelected.tr_toMe} requestedDate={requestedDate}/>
                            </div>
                        </div>)
            }
            <div>
                <div className="">
                    <Modal
                        isClose={() => closeModal()}
                        isOpen={props.modalIsOpen}>
                        {function () {
                            switch (props.purpose) {
                                case 'create':
                                    return <CreateRoom setIsOpen={props.setIsOpen} />;
                                default:
                                    return <NewMessage setIsOpen={props.setIsOpen} />;
                            }
                        }()}
                    </Modal>
                </div>
            </div>
        </div>);
})

export default General;