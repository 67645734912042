import {useContext, memo, useState, useEffect} from 'react'
import {Menu} from '@headlessui/react'
import {observer} from "mobx-react-lite"
import {SocketContext} from '../../const/socket';
import userAvatar from "../../assets/images/user_avatar.png"
import {useStore} from '../../store';
import {BACKEND_URL} from '../../const/Constants';
import SOCKET from "../../const/socketNames"
import AlertComponent from "../../utils/Alert";
import {ALERT_MESSAGE_TYPE_DANGER} from "../../const/Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCogs} from "@fortawesome/free-solid-svg-icons";
import { langContext } from '../../App';
import { langUserElement } from './userElementTranslate';
import "../../pages/homepage.css"
import MessageTypes from '../modal/MessageTypes';



let style = {
    width: "100%"
}

const UserElement = observer(({setCloseMessagesModal, closeMessagesModal, user, status, setIsOpen, setSelectedUser, setWaitAnswerCall}) => {
    const lang = useContext(langContext);
    let showBySelected = langUserElement[lang]

    const {userStore, chatStore, usersStore, videoRoomStore} = useStore()
    const socket = useContext(SocketContext)

    const [messageTypes, setMessageTypes] = useState(false)

    useEffect(() => setMessageTypes(false), [closeMessagesModal])

    const chatHandleClick = () => {
        socket.emit(SOCKET.CHAT_CREATE, {username: userStore.username, guestUsername: user.username})
        usersStore.removeUnviewedChats(user.username)
        chatStore.setUsername(user.username)
        socket.emit(SOCKET.CHAT_VIEWED_ROOMS, {name: userStore.username, caller: user.username})
    }

    const chatNewTabHandleClick = () => {
        socket.emit(SOCKET.CHAT_CREATE, {username: userStore.username, guestUsername: user.username, type: 'blank'})
        usersStore.removeUnviewedChats(user.username)
        socket.emit(SOCKET.CHAT_VIEWED_ROOMS, {name: userStore.username, caller: user.username})
    }

    const videoCallHandleClick = async (username) => {
        const [result, myHasBusy] = await Promise.all([userStore.checkUserIsBusy({username}), userStore.checkUserIsBusy({username: userStore.username})])

        if (!result.status) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, showBySelected.tr_userIsBusy)
            return
        }


        if (!myHasBusy.status) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, showBySelected.tr_youAreBeingCalled)
            return
        }

        await videoRoomStore.setPersonalRoom({answer_username: userStore.username})
        await videoRoomStore.getVideoRoomByName()

        setWaitAnswerCall(true)
        setIsOpen(true)
        socket.emit(SOCKET.MAKE_PRIVATE_CALL, {
            name: username,
            caller: userStore.username,
            room_id: videoRoomStore.roomId
        })
    }


    return (
        <li className="eachuser" >
            <div className='each-user-div ' >
                <div className='each-user-div fontstyles'>
                    <img src={user.image ? `${BACKEND_URL}${user.image}` : userAvatar} className={status ? "each-img online-border" : "each-img"}
                         alt="Avatar"/>
                          {
                        usersStore.unviewedChats.includes(user.username) && (<>
                            <span
                                className="msg-circle-animated animate-ping absolute inline-flex h-3 w-3 rounded-full bg-red-400 opacity-75"/>
                            <span className='relative bg-red-400 rounded-full msg-circle'/>
                        </>)
                    }
                       <p> {(user.name || user.last_name) ?
                                    user.name + ' ' + user.last_name + ' (' + user.username +')' : user.username}</p>
                </div>
                <div className='each-chontrollers-div' onClick={() => setSelectedUser(user.username)}>
                    <div className="each-div1" onClick={() => videoCallHandleClick(user.username)}>
                        <img src="/videocall.png" className='each-chontroller' />
                    </div>  
                    <div className="each-div2" onClick={(e)=> {setCloseMessagesModal(!closeMessagesModal);setTimeout(() => setMessageTypes(!messageTypes), 0) ; e.stopPropagation()}}> 
                        <img src="/newmessage.png" className='each-chontroller' />
                    </div>
                    <div className="mob-each-div" onClick={(e)=> {chatNewTabHandleClick() ; e.stopPropagation()}}> 
                        <img src="/newmessage.png" className='each-chontroller' />
                    </div>
                </div>
                {messageTypes && <MessageTypes chatHandleClick={chatHandleClick} chatNewTabHandleClick={chatNewTabHandleClick}
                  tr_message={showBySelected.tr_message} tr_messageInNewPage={showBySelected.tr_messageInNewPage} setMessageTypes={setMessageTypes}/>}
                
            </div>
        </li>
    )
})
export default memo(UserElement);
