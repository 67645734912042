export const langLogin = {
    "arm" : 
    {tr_notMatch: "Գաղտնաբառը չի համընկնում",
        tr_pleaseFill: "Խնդրում եմ լրացնել դաշտերը",
        tr_passLangValid: "Գաղտնաբառր պետք է ունենա մինիմում 8 սիմվոլ",
        tr_videoConnection: "ՏԵսակոնֆերենս Կապ",
        tr_password: "Գաղտնաբառ",
        tr_passwordConfirm: "Հաստատել Գաղտնաբառը",
        tr_passwordChange: "Փոփոխել գաղտնաբառը",
        tr_name: "Անուն",
        tr_logIn: "Մուտք գործել",
        tr_help: "Օգնություն",
        tr_registr: "Գրանցվել",
        tr_confSystem: "ՏԵՍԱՁԱՅՆԱՅԻՆ ԿՈՆՖԵՐԱՆՍ ԿԱՊԻ ՀԱՄԱԿԱՐԳ",
        tr_fillThePin: "Լրացնել PIN կոդը",
        tr_pinNotExist: "Պին կոդը գոյություն չունի"
    },
    "eng": {tr_notMatch: "The password does not match",
        tr_pleaseFill: "Please fill in the fields",
        tr_passLangValid: "The password should have at least 8 characters",
        tr_videoConnection: "Video conference connection",
        tr_password: "Password",
        tr_passwordConfirm: "Confirm the password",
        tr_passwordChange: "Change the password",
        tr_name: "Name",
        tr_logIn: "Log in",
        tr_help: "Help",
        tr_registr: "Register",
        tr_confSystem: "VIDEO CONFERENCE COMMUNICATION SYSTEM",
        tr_fillThePin: "Fill in the PIN code",
        tr_pinNotExist: "The pin code does not exist"
    },
    "fr": {tr_notMatch: "Mot de passe invalide",
        tr_pleaseFill: "Remplissez les champs requis",
        tr_passLangValid: "Le mot de passe doit contenir au minimum 8 caractères",
        tr_videoConnection: "Connexion de vidéoconférence",
        tr_password: "Mot de passe",
        tr_passwordConfirm: "Confirmer le mot de passe",
        tr_passwordChange: "Changer le mot de passe",
        tr_name: "Nom",
        tr_logIn: "Identifiez-vous pour accéder",
        tr_help: "Aide",
        tr_registr: "Enregistrement",
        tr_confSystem: "",
        tr_fillThePin: "Saisir le code PIN",
        tr_pinNotExist: "Le Code PIN n’existe pas"
    },
    "arb": {tr_notMatch: "كلمة السرّ غير متطابقة",
        tr_pleaseFill: "يرجى ملء الحقول",
        tr_passLangValid: "كلمة السرّ يجب أن يكون مؤلّفاً من 8 رموز على الأقلّ",
        tr_videoConnection: "المكالمة الجماعية عبر الفيديو",
        tr_password: "كلمة السرّ",
        tr_passwordConfirm: "تأكيد كلمة السرّ",
        tr_passwordChange: "تغيير كلمة السرّ",
        tr_name: "الاسم",
        tr_logIn: "تسجيل الدخول",
        tr_help: "المساعدة",
        tr_registr: "التسجيل",
        tr_confSystem: "",
        tr_fillThePin: "ملء كلمة المرور",
        tr_pinNotExist: "رمز المرور غير موجودة"
    }
        
}