// import validator from "validator"

export const  passwordValidation = (password)=> {
    // const isValid = validator.isStrongPassword(password, {
    //     minLength: 6
    //   })


    const isValid = password.length > 7
    
    return {
        isValid,
        message: "Գաղտնաբառը պետք է ունենա մինիմում 8 սիմվոլ"
    }
}

export const usernameValidation = (username) => {
      const isValid =  username.length > 2 && username.length < 18

      return {isValid, message: "Օգտանունը պետք է լինի մինիմում 3 սիմվոլ և մաքսիմում 18 սիմվոլ"}
}

export const usernameSymbolValidation = (username) => {
    const allowed = /[A-z0-9\._\-]/gm
    const isValid = username && username.match(allowed).join("") === username

    return {isValid, message: "Օգտանունը պարունակում է չթույլատրված սիմվոլներ"}
}