export const langGeneratePin = {
    "arm": {
        tr_pleaseSelectPin: "Խնդրում եմ ընտրեք փին գաղտնաբառ",
        tr_generatePin: "Գեներացնել նոր Pin Code",
        tr_pinCode: "Պին կոդ",
        tr_numberOfUsage: "Օգտագործման Քանակ",
        tr_addressee: "Ում է տրվելու",
        tr_generate: "Գեներացնել",
        tr_confirm: "Հաստատել"
    },
    "eng": {
        tr_pleaseSelectPin: "Please select a PIN password",
        tr_generatePin: "Generate a new pin code",
        tr_pinCode: "Pin code",
        tr_numberOfUsage: "Number of usage",
        tr_addressee: "Addressee",
        tr_generate: "Generate",
        tr_confirm: "Confirm"
    },
    "fr": {
        tr_pleaseSelectPin: "Sélectionnez un PIN/un mot de passe",
        tr_generatePin: "Générer un nouveau Code PIN",
        tr_pinCode: "Code PIN",
        tr_numberOfUsage: "Nombre d’utilisation",
        tr_addressee: "Destinataire",
        tr_generate: "Générer",
        tr_confirm: "Approuver"
    },
    "arb": {
        tr_pleaseSelectPin: "يرجى اختيار كلمة المرور",
        tr_generatePin: "إنشاء كلمة مرور جديدة",
        tr_pinCode: "كلمة المرور",
        tr_numberOfUsage: "عدد مرات الاستخدام",
        tr_addressee: "سوف يتمّ المنح لـ",
        tr_generate: "إنشاء",
        tr_confirm: "تأكيد"
    }
}