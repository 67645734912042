export const langChangePass = {
    "arm": {
        tr_passDoesNotMatch: "Գաղտնաբառը չի համընկնում",
        tr_passLengthValid: "Գաղտնաբառր պետք է ունենա մինիմում 8 սիմվոլ",
        tr_confirm: "Հաստատել",
        tr_oldPass: "Հին Գաղտնաբառ ",
        tr_newPass: "Նոր Գաղտնաբառ",
        tr_confirmPass: "Հաստատել Գաղտնաբառը",
    },
    "eng": {
        tr_passDoesNotMatch: "The password does not match",
        tr_passLengthValid: "The password should have at least 8 characters",
        tr_confirm: "Confirm",
        tr_oldPass: "Old passowrd",
        tr_newPass: "New passowrd",
        tr_confirmPass: "Confirm password",
    },
    "fr": {
        tr_passDoesNotMatch: "Mot de passe invalide",
        tr_passLengthValid: "Le mot de passe doit contenir au minimum 8 caractères",
        tr_confirm: "Approuver",
        tr_oldPass: "",
        tr_newPass: "",
        tr_confirmPass: "",
    },
    "arb": {
        tr_passDoesNotMatch: "كلمة السرّ غير متطابقة",
        tr_passLengthValid: "كلمة السرّ يجب أن يكون مؤلّفاً من 8 رموز على الأقلّ",
        tr_confirm: "تأكيد",
        tr_oldPass: "",
        tr_newPass: "",
        tr_confirmPass: "",
    }
}