export const langUser = {
    "arm": {
        tr_edit: "Խմբագրել",
        tr_activity: "Ակտիվություն",
        tr_message: "Նամակ",
        tr_delete: "Ջնջել"
    },
    "eng": {
        tr_edit: "Edit",
        tr_activity: "Activity",
        tr_message: "Message",
        tr_delete: "Delete"
    },
    "fr": {
        tr_edit: "Editer",
        tr_activity: "Activité",
        tr_message: "Courrier/message",
        tr_delete: "Supprimer"
    },
    "arb": {
        tr_edit: "تحرير",
        tr_activity: "النشاط",
        tr_message: "رسالة",
        tr_delete: "حذف"
    }
}