import React, {useContext, useEffect, useState} from "react";
import VideoRoom from "../states/VideoRoom";
import {Menu} from "@headlessui/react";
import Modal from "../components/modal/CustomModal"
import AlertComponent from "../utils/Alert";
import {observer} from "mobx-react-lite";
import {ALERT_MESSAGE_TYPE_DANGER, ALERT_MESSAGE_TYPE_SUCCESS} from "../const/Constants";
import {useStore} from "../store";
import {ApproveOrCancel} from "../components/modal/ApproveOrCancel";
import {SocketContext} from "../const/socket"
import SOCKET from "../const/socketNames"
import {options, classNames, getClassByStatus, popUp} from "../utils/helpers"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClock, faPlay, faTimes} from "@fortawesome/free-solid-svg-icons";
import { axiosInstance } from "../const/axiosInstance";
import { langContext } from "../App";
import { langMeetingList } from "./meetingsListTranslate";


const getRoomIconByStatus = ({status, username, refused_users}) => {
    if (status === "pending") {
        if (refused_users.includes(username))
            return <FontAwesomeIcon className="fas fa-times text-red-500" icon={faTimes}/>

        return <FontAwesomeIcon className="far fa-clock" icon={faClock}/>
    } else if (status === "declined") {
        return <FontAwesomeIcon className=" text-red-500" icon={faTimes}/>
    } else if (status === "started") {
        if (refused_users.includes(username))
            return <FontAwesomeIcon className=" text-red-500" icon={faTimes}/>

        return <FontAwesomeIcon icon={faPlay}/>
    } else {
        return <FontAwesomeIcon icon={faCheck} className="fa fa-check text-green-500" aria-hidden="true"/>
    }

}


const UpComingMeetingsList = observer(({title, requestedDate}) => {
    const lang = useContext(langContext)
    let showBySelected = langMeetingList[lang]

    const [modalIsOpen, setIsOpen] = useState(false);
    const [decline, setDecline] = useState(false)
    const [selectedRoom, setSelectedRoom] = useState("")
    const [deleteMeeting, setDeleteMeeting] = useState(false)
    const {userStore} = useStore()

    // TODO is dublicated in Meetingroom
    const [dateState, setDateState] = useState(0);

    useEffect(() => {
        setDateState(requestedDate)
    }, [requestedDate]);
    // END

    const socket = useContext(SocketContext)


    useEffect(() => {
        VideoRoom.getMeInvitedVideoMeetings()

        socket.on(SOCKET.MEETINGS_ME_INVITED, () => {
            VideoRoom.getMeInvitedVideoMeetings()
        })
        socket.on(SOCKET.MEETING_STARTED, () => {
            VideoRoom.getMeInvitedVideoMeetings()
        })

        return () => socket.off(SOCKET.MEETINGS_ME_INVITED)
    }, [])

    function openModal() {
        setIsOpen(true);
    }


    function closeModal() {
        setIsOpen(false);
        setDecline(false)
        setDeleteMeeting(false)
    }

    // get_me_invited_meetings
    const connectMeeting = async (roomId, start_date) => {
        const [myHasBusy] = await Promise.all([userStore.checkUserIsBusy({username: userStore.username})])

        if (!myHasBusy.status) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, "Դուք արդեն զանգի մեջ եք")
            return
        }

        if (new Date(Date.now() - dateState).toISOString() < new Date(new Date(start_date).setMinutes(new Date(start_date).getMinutes() - 10)).toISOString()) {
            const date = new Date(start_date).toString().split(" ")
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, `Հանդիպումը կսկսվի ${date[1]} ${date[2]}   ամսաթվի ժամը ${date[4]} -ին, որին կարող եք միանալ արավելագույնը 10 րոպե շուտ `)
        } else {
            popUp(`/video-room/${roomId}?purpose=Group`)
        }

    }

    const declineInviteMeeting = (room_id) => {
        setSelectedRoom(room_id)
        setDecline(true);
        openModal()
    }

    const approveVideoMeetingDecline = async (reasonMessage) => {
        const result = await VideoRoom.declineMeInviteMeeting(selectedRoom._id)
        const status = result.status ? ALERT_MESSAGE_TYPE_SUCCESS : ALERT_MESSAGE_TYPE_DANGER
        if (result.status) {
            await VideoRoom.getMeInvitedVideoMeetings()
        }
        socket.emit(SOCKET.APPROVE_OR_DECLINE_MEETING, [selectedRoom.username], reasonMessage, userStore.username, "decline")
        AlertComponent(status, result.message)
        closeModal()
    }

    const cancelVideMeetingDecline = () => {
        closeModal()
    }

    const approveMeetingDelete = async () => {
        const result = await VideoRoom.deleteMeInviteMeeting(selectedRoom._id)
        const status = result.status ? ALERT_MESSAGE_TYPE_SUCCESS : ALERT_MESSAGE_TYPE_DANGER
        AlertComponent(status, result.message)
        if (result.status) {
            await VideoRoom.getMeInvitedVideoMeetings()
        }
        setSelectedRoom({})
        closeModal()
    }

    const checkLocaleTime = (roomDate, type) => {
        if (type === 'pending') {
            const roomTime = new Date(roomDate).toLocaleTimeString('en', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                hour12: false,
                minute: 'numeric'
            });
            return new Date(Date.now() - dateState).toLocaleTimeString('en', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                hour12: false,
                minute: 'numeric'
            }) > roomTime;
        } else
            return false;
    }

    async function changeDecline(room, name) {
            await axiosInstance.post("/devices/changefromdecline", {id:room._id, name})
    }

    const isDeclined = (room) => {
        if (!room.invited_users.some(el => el === userStore.username))
            return 'declined';

        return 'warning';
    }

    return (
        <>
            <div className="">
                <div className="listtitle-div">
                        <span className='fontstyles font-bold titeles' style={{"margin-left":"16px"}}>{title}</span>
                </div>
                { window.innerWidth >= 600 ? 
                    (<div className="list-scroll">
                        <table className="w-full">
                            <thead>
                                 <tr>
                                    <th className="describtions fontstyles">{showBySelected.tr_name}</th>
                                    <th className="describtions fontstyles"><div className="dash"></div>{showBySelected.tr_participant}</th>
                                    <th className="describtions fontstyles"><div className="dash"></div>{showBySelected.tr_time}</th>
                                    <th className="describtions fontstyles"><div className="dash"></div>{showBySelected.tr_status}</th>
                                    <th style={{"width" :"50px"}} className="describtions"><div className="dash"></div></th>
                                 </tr>
                            </thead>
                            <tbody>
                                {VideoRoom.invite_meetings.map((room, idx) => (
                                    <Menu key={idx} as="tr" className="fontstyles hover:text-gray-800 hover:bg-gray-100 list-tr">                                       
                                        <td className='list-td'>{room.name}</td>
                                        <td className='list-td'>{room.participant_count}</td>
                                        <td className='list-td'>{new Date(room.start_date).toLocaleDateString('en-GB', options)}</td>
                                        <td>
                                            <div className={"status-td " + getClassByStatus({
                                                    tatus: checkLocaleTime(room.start_date, room.status) ? isDeclined(room) : room.status,
                                                    refused_users: room.refused_users,
                                                    username: userStore.username
                                                    })}>
                                                {getRoomIconByStatus({
                                                    status: room.status,
                                                    refused_users: room.refused_users,
                                                    username: userStore.username
                                                })}
                                            </div>
                                        </td>
                                        <Menu.Button as={'td'} className="hover:cursor-pointer">
                                            <div className="settings-img">
                                                <img src="/Iconsettings.png" />
                                            </div>
                                            <Menu.Items as={'td'} className="list-control-div">
                                                <div className="py-1">
                                                    {
                                                        room.status !== "approved" && room.status !== "declined" && !room.blocked_names.includes(userStore.username) && <>
                                                            <Menu.Item>
                                                                    <button
                                                                            onClick={() =>{changeDecline(room, userStore.username);connectMeeting(room._id, room.start_date)} }
                                                                            className='list-controller-btn participate-controller'>
                                                                        <img src="/participate.png"/>
                                                                        {showBySelected.tr_toParticipate}
                                                                    </button>
                                                            </Menu.Item>
                                                            <hr/>
                                                        </>
                                                    }
                                                    {
                                                        room.status !== "approved" && room.status !== "declined" && !room.refused_users.includes(userStore.username) &&
                                                        <Menu.Item>
                                                                <button
                                                                        onClick={() => {
                                                                            declineInviteMeeting(room._id);
                                                                            setSelectedRoom(room)
                                                                        }}
                                                                        className="list-controller-btn cencel-controller">
                                                                    <img src="/cencelred.png" />
                                                                    {showBySelected.tr_cancel}
                                                                </button>
                                                        </Menu.Item>
                                                    }

                                                    <hr/>
                                                    {
                                                        <Menu.Item>
                                                            <button onClick={() => {
                                                                    setDeleteMeeting(true);
                                                                    setSelectedRoom(room);
                                                                    openModal()
                                                                    }} className="list-controller-btn cencel-controller">
                                                                <img src="/deletered.png" />
                                                                {showBySelected.tr_delete}        
                                                            </button>
                                                        </Menu.Item>
                                                    }
                                                </div>
                                            </Menu.Items>
                                        </Menu.Button>
                                    </Menu>
                                ))}
                            </tbody>
                        </table>

                    {
                        decline &&
                        <Modal
                            isClose={() => closeModal()}
                            isOpen={modalIsOpen}>
                            <ApproveOrCancel approveHandler={approveVideoMeetingDecline}
                                            cancelHandler={cancelVideMeetingDecline} title={showBySelected.tr_canclReason}/>

                        </Modal>
                    }

                    {
                        deleteMeeting &&
                        <Modal
                            isClose={() => closeModal()}
                            isOpen={modalIsOpen}>

                            <ApproveOrCancel approveHandler={approveMeetingDelete} cancelHandler={closeModal}
                                            reason_need={false}/>
                        </Modal>
                    }
            </div>) : (
                <div className="meeting-list-mob">
                    <div className="mob-conf-list-my">              
                        {VideoRoom.invite_meetings.map((room, idx) => (
                            <div className="mob-each-conf">
                                 <Menu key={idx} as="tr" className="fontstyles list-tr">
                                     <Menu.Button as={'div'} className="my-mob-list">
                                        <div><p className="headers">{showBySelected.tr_name}</p><p className='list-td'>{room.name}</p></div>
                                        <hr className="headers-div"/>
                                        <div><p className="headers">{showBySelected.tr_participant}</p><p className='list-td'>{room.participant_count}</p></div>
                                        <hr className="headers-div"/>
                                        <div><p className="headers">{showBySelected.tr_time}</p><p className='list-td'>{new Date(room.start_date).toLocaleDateString('en-GB', options)}</p></div>
                                        <hr className="headers-div"/>
                                        <div><p className={"status-td " + getClassByStatus({
                                            status: checkLocaleTime(room.start_date, room.status) ? isDeclined(room) : room.status,
                                            refused_users: room.refused_users,
                                            username: userStore.username
                                        })}>
                                            {getRoomIconByStatus({
                                                status: checkLocaleTime(room.start_date, room.status) ? isDeclined(room) : room.status,
                                                refused_users: room.refused_users,
                                                username: userStore.username
                                            })}</p>
                                        </div>
                                        <Menu.Items as={'td'} className="list-control-div">
                                            <div className="py-1">
                                                {
                                                    room.status === "started" && !room.refused_users.includes(userStore.username) && <>
                                                        <Menu.Item >
                                                                <button
                                                                    onClick={() => connectMeeting(room._id, room.start_date)}
                                                                    className='list-controller-btn participate-controller'><img src="/participate.png"/>{showBySelected.tr_toParticipate}</button>                                              
                                                        </Menu.Item>
                                                        <hr/>

                                                    </>
                                                }
                                                {
                                                    room.status !== "approved" && room.status !== "declined" && !room.refused_users.includes(userStore.username) &&
                                                    <Menu.Item>                                            
                                                            <button
                                                                onClick={() => {
                                                                    declineInviteMeeting(room._id);
                                                                    setSelectedRoom(room)
                                                                }}
                                                                className="list-controller-btn cencel-controller"><img src="/cencelred.png" />{showBySelected.tr_cancel}</button>                                            
                                                    </Menu.Item>
                                                }

                                                {
                                                    <Menu.Item>
                                                        
                                                            <button onClick={() => {
                                                                setDeleteMeeting(true);
                                                                setSelectedRoom(room);
                                                                openModal()
                                                            }} className="list-controller-btn cencel-controller"><img src="/deletered.png" />{showBySelected.tr_delete}</button>
                                                    </Menu.Item>
                                                }
                                            </div>
                                        </Menu.Items>
                                    </Menu.Button>
                                </Menu>
                            </div>
                    
                    ))}
                    
                    {
                        decline &&
                        <Modal
                            isClose={() => closeModal()}
                            isOpen={modalIsOpen}>
                            <ApproveOrCancel approveHandler={approveVideoMeetingDecline}
                                            cancelHandler={cancelVideMeetingDecline} title={showBySelected.tr_canclReason}/>

                        </Modal>
                    }

                    {
                        deleteMeeting &&
                        <Modal
                            isClose={() => closeModal()}
                            isOpen={modalIsOpen}>

                            <ApproveOrCancel approveHandler={approveMeetingDelete} cancelHandler={closeModal}
                                            reason_need={false}/>
                        </Modal>
                    }
                    </div>
                </div>)
           }
        </div>
    </>
    )
    })


export default UpComingMeetingsList
