export const langUserElement = {
    "arm": {
        tr_userIsBusy: "Օգտվողը զբաղված է",
        tr_youAreBeingCalled: "Դուք արդեն զանգի մեջ եք",
        tr_call: "Զանգ",
        tr_message: "Նամակ",
        tr_messageInNewPage: "Նամակ նոր էջում"
    },
    "eng": {
        tr_userIsBusy: "User is busy",
        tr_youAreBeingCalled: "You are being called",
        tr_call: "A call",
        tr_message: "Message",
        tr_messageInNewPage: "A message in a new page"
    },
    "fr": {
        tr_userIsBusy: "L'utilisateur est occupé",
        tr_youAreBeingCalled: "Vous avez un appel",
        tr_call: "Appel",
        tr_message: "Courrier/message",
        tr_messageInNewPage: "Un courrier/message sur une nouvelle page"
    },
    "arb": {
        tr_userIsBusy: "المستخدم مشغول",
        tr_youAreBeingCalled: "أنت في حالة اتّصال",
        tr_call: "اتّصال",
        tr_message: "رسالة",
        tr_messageInNewPage: "رسالة في صفحة جديدة"
    }
}