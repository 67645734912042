import React, {useContext, useEffect, useState} from 'react';
import { langGeneratePin } from './generatePinTranslate';
import { langContext } from '../../../App';
import { axiosInstance } from '../../../const/axiosInstance';

const AddPin = () => {
    const lang = useContext(langContext);
    let showBySelected = langGeneratePin[lang]

    const [pin, setPin] = useState('');
    const [link, setLink] = useState("https://")
    const [requests, setRequests] = useState([])


    async function create() {
        const result = await axiosInstance.post("/auth/add-pin-with-link", {pin,link})
        setPin("")
        setLink("")
    }

    async function deleteRequest(id) {
        await axiosInstance.post("/auth/delete-request", {id})
        setRequests(requests.filter((el) => el._id != id))
    }

    useEffect(() => {
        const a = async () => {
        let results = await axiosInstance.get("/auth/pin-requestes")
        setRequests(results.data.data)
       }
       a()
    }, [])



    return (
        <div>
            <div className="relative z-0 w-full group m-2 mb-4">
                <input type='text' name="code" id="code"
                       className="block w-full py-2.5 px-0  text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                       placeholder=" " value={pin} onChange={(e) => setPin(e.target.value)}/>
                <label htmlFor="code"
                       className="peer-focus:font-medium absolute text-sm left-0 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">{showBySelected.tr_pinCode}</label>
            </div>
            <div className="relative z-0 w-full group m-2">
                <input type='text' name="used_limit" id="used_limit"
                       className="block w-full py-2.5 px-0  text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                       placeholder=" " value={link} onChange={(e) => setLink(e.target.value)}/>
                <label htmlFor="used_limit"
                       className="peer-focus:font-medium absolute text-sm left-0 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Հղում</label>
            </div>
            <button className="bg-blue-600 text-white rounded p-2 m-2" onClick={create}>{showBySelected.tr_confirm}</button>
            <div style={{width: "65%", margin: "auto"}}>{requests.map((el, i) => (
                <div key={i} className="border-b-2 border-gray-200" style={{display: "flex", width: "100%", justifyContent: "space-between", margin: "5 15 0 15"}}>
                    <div>Հերախոսահամար: {el.phone}</div>
                    <div>Էլ․ հասցե: {el.email}</div>
                    <button onClick={() => deleteRequest(el._id)} style={{color: "red"}}>X</button>
                    
                </div>
            ))}
            </div>
        </div>
    )
    }

    export default AddPin