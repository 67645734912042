export const langUserForm = {
    "arm": {
        tr_confirm: "Հաստատել",
        tr_insertPhoto: "Տեղադրել նկար",
        tr_removePhoto: "Ջնջել նկարը",
        tr_name: "Անուն",
        tr_surname: "Ազգանուն",
        tr_patronymic: "Հայրանունը",
        tr_residenc: "Բնակավայր",
        tr_workplace: "Աշխատավայր",
        tr_telephone: "Հեռախոս",
        tr_position: "Պաշտոնը",
        tr_email: "Էլ․ փոստ",
        tr_image: "Նկար"
        
    },
    "eng": {
        tr_confirm: "Confirm",
        tr_insertPhoto: "Insert photo/picture",
        tr_removePhoto: "Remove photo/picture",
        tr_name: "Name",
        tr_surname: "Surname",
        tr_patronymic: "Patronymic",
        tr_residenc: "Residenc",
        tr_workplace: "Workplace",
        tr_telephone: "Telephone",
        tr_position: "Position",
        tr_email: "E-mail",
        tr_image: "Image"
    },
    "fr": {
        tr_confirm: "Approuver",
        tr_insertPhoto: "Insérer une photo/image",
        tr_removePhoto: "Supprimer la photo/l’image",
        tr_name: "",
        tr_surname: "",
        tr_patronymic: "",
        tr_residenc: "",
        tr_workplace: "",
        tr_telephone: "",
        tr_position: "",
        tr_email: "",
        tr_image: ""
      
    },
    "arb": {
        tr_confirm: "تأكيد",
        tr_insertPhoto: "إدخال صورة",
        tr_removePhoto: "حذف الصورة",
        tr_name: "",
        tr_surname: "",
        tr_patronymic: "",
        tr_residenc: "",
        tr_workplace: "",
        tr_telephone: "",
        tr_position: "",
        tr_email: "",
        tr_image: ""
    }
}