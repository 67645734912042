import React, { useContext, useState } from "react"
import { ALERT_MESSAGE_TYPE_DANGER } from "../../const/Constants"
import { langContext } from "../../App"
import AlertComponent from "../../utils/Alert"
import "./style.css"
import { langCheckDevicesStatus } from "./checkDeviceStatusTranslate"
import "../../container/profile.css"



const CheckDevices = () => {
  const lang = useContext(langContext);
  let showBySelected = langCheckDevicesStatus[lang]

  const [start, setStart] = useState(false)
  const [startVideo, setStartVideo] = useState(false)
  const [audioContext, setAudioContext] = useState(null)

  const checkAudio = () => {
    setStart(true)
    navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false
    })
      .then(function (stream) {
        const allPids = [...document.querySelectorAll('.pid')];
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;

        microphone.connect(analyser);
        analyser.connect(scriptProcessor);
        setAudioContext(audioContext)
        scriptProcessor.connect(audioContext.destination);
        scriptProcessor.onaudioprocess = function () {
          const array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          const arraySum = array.reduce((a, value) => a + value, 0);
          const average = arraySum / array.length;
          colorPids(average, allPids);
        };
      })
      .catch(function (err) {
        AlertComponent(ALERT_MESSAGE_TYPE_DANGER, showBySelected.tr_microphoneIsNotTurnedOn)
      });
  }
  const cancelAudio = () => {
    if (audioContext) {
      setStart(false)
      audioContext.close()
    }
  }

  function colorPids(vol, allPids) {
    const numberOfPidsToColor = Math.round(vol / 10);
    const pidsToColor = allPids.slice(0, numberOfPidsToColor);
    for (const pid of allPids) {
      pid.style.backgroundColor = "#e6e7e8";
    }
    for (const pid of pidsToColor) {
      pid.style.backgroundColor = "#69ce2b";
    }

  }


  const checkCamera = () => {
    //todo: many bug double view camera
    navigator.mediaDevices.getUserMedia({
      audio: false,
      video: true
    })
      .then(function (stream) {
        const videoGrid = document.getElementById("video-grids");
        const videoElement = document.getElementById("video_test")
        if (videoElement) {
          return
        }
        const video = document.createElement("video");
        video.setAttribute('id', 'video_test')
        video.srcObject = stream;

        video.addEventListener("loadedmetadata", () => {

          video.play();
          setStartVideo(true)
          videoGrid.append(video);
        });
      })
      .catch(e => {
       AlertComponent(ALERT_MESSAGE_TYPE_DANGER, showBySelected.tr_cameraIsNotTurnedOn)
    })
  }

  const cancelCamera = () => {
    const videoElement = document.getElementById("video_test")
    videoElement?.captureStream().getTracks()?.forEach(track => track.readyState === 'live' && track.stop())
    videoElement && videoElement.remove()
    setStartVideo(false)
  }

    const testAudio = () => {
        const audioElement = document.getElementById("audio")
        audioElement.play()
    }
    const stopAudio = () => {
        const audioElement = document.getElementById("audio")
        audioElement.pause()
    }

  return (
    <div className="width100 height100">
      
        <div className='checking-div'>
           <div className="microphone">
             <h3>{showBySelected.tr_microphoneTesting}</h3>
             <div className="flex-divs">
                <button onClick={checkAudio} className="check-btn">{showBySelected.tr_test}</button>
                <button onClick={cancelAudio} className="cancel-btn">{showBySelected.tr_cancel}</button>
             </div>
           </div>
           <div className="camera">
             <h3>{showBySelected.tr_cameraCheck}</h3>
             <div className="flex-divs">
                 <button onClick={checkCamera} className="check-btn" disabled={startVideo}>{showBySelected.tr_test}</button>
                 <button onClick={cancelCamera} className="cancel-btn">{showBySelected.tr_cancel}</button>
             </div>
          </div>
          <div className="">
            <h3>{showBySelected.tr_audioTesting}</h3>
            <div className="flex-divs">
                <button onClick={testAudio} className="check-btn">{showBySelected.tr_test}</button>
                <button onClick={stopAudio} className="cancel-btn">{showBySelected.tr_cancel}</button>
            </div>
             <audio id={'audio'} controls={false}>

                 <source src="/callme.mp3"/>
             </audio>
          </div>
        </div>
        <div className="video-div">
          <div id="video-grids"></div>
        </div>
        {/* <div className="audio-section microphone"> */}
          <div className={`pids-wrapper mrleft ${start ? "flex" : "hidden"}`} style={{"width":"40%"}}>
            <div className="pid" />
            <div className="pid" />
            <div className="pid" />
            <div className="pid" />
            <div className="pid" />
            <div className="pid" />
            <div className="pid" />
            <div className="pid" />
            <div className="pid" />
            <div className="pid" />
          </div>
        {/* </div> */}
    </div>
  )

}



export default CheckDevices 