import React from 'react'
import "../pages/login.css"



export default function Logo() {
  return (
    <div>
      <img src='/mergelyan.png' className='mergelyan-logo'/>
    </div>
  )
}
