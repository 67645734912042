import React, { useEffect } from 'react';
import {Button, Card, Col, Input, Menu, MenuProps, message, Row, Space, Typography, Upload, UploadFile} from "antd";
import {CopyOutlined, UploadOutlined} from "@ant-design/icons";
import {useAppDispatch, useAppSelector} from "./store/hooks.ts";
import {startPeer, stopPeerSession} from "./store/peer/peerActions.ts";
import * as connectionAction from "./store/connection/connectionActions.ts"
import {DataType, PeerConnection} from "./helpers/peer.ts";
import {useAsyncState} from "./helpers/hooks.ts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faArrowRightArrowLeft, faClose} from "@fortawesome/free-solid-svg-icons"
import { useStore } from '../store.jsx';

const {Title} = Typography
type MenuItem = Required<MenuProps>['items'][number]

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

export const FileUploadPeer: React.FC = ({setIsOpenP2P, setFromP2P}: any) => {

    const peer = useAppSelector((state) => state.peer)
    const connection = useAppSelector((state) => state.connection)
    const dispatch = useAppDispatch()
    const { userStore, chatStore, usersStore } = useStore()

    useEffect(() => {
        handleStartSession();
        dispatch(connectionAction.changeConnectionInput(chatStore.username));
        setTimeout(handleConnectOtherPeer, 1000)
    }, [])

    const handleStartSession = () => {
        dispatch(startPeer())
    }

    const handleStopSession = async () => {
        await PeerConnection.closePeerSession(chatStore.username)
        dispatch(stopPeerSession())
    }

    const handleConnectOtherPeer = () => {
        dispatch(connectionAction.connectPeer(chatStore.username || ""))
    }

    const [fileList, setFileList] = useAsyncState([] as UploadFile[])
    const [sendLoading, setSendLoading] = useAsyncState(false)

    const handleUpload = async () => {
        if (fileList.length === 0) {
            message.warning("Please select file")
            return
        }
        if (!connection.selectedId) {
            message.warning("Please select a connection")
            return
        }
        try {
            await setSendLoading(true);
            let file = fileList[0] as unknown as File;
            let blob = new Blob([file], {type: file.type});

            await PeerConnection.sendConnection(connection.selectedId, {
                dataType: DataType.FILE,
                file: blob,
                fileName: file.name,
                fileType: file.type
            })
            await setSendLoading(false)
            message.info("Ֆայլը հաջողությամբ ուղարկվեց")
        } catch (err) {
            await setSendLoading(false)
            message.error("Սխալմունք, փորձեք կրկին")
        }
    }

    return (
        <Row justify={"center"} align={"top"}>
            
           
                <Card>
               
                 
                        <div hidden={!peer.started}>
                          
                            <Card>
                                {
                                    connection.list.length === 0
                                        ? usersStore.onlineUsers.includes(chatStore.username) ? <div style={{textAlign: "center"}}>Խնդրում ենք սպասել ...</div>
                                        : <div>Օգտատերը հասանելի չէ</div>
                                        : <div style={{textAlign: "center"}}>Դուք կապի մեջ եք {connection.list[0]}֊ի հետ</div>
                                }

                            </Card>
                            <Card>
                                <Upload fileList={fileList}
                                        maxCount={1}
                                        onRemove={() => setFileList([])}
                                        beforeUpload={(file) => {
                                            setFileList([file])
                                            return false
                                        }}>
                                    <Button disabled={connection.list.length === 0}>Ընրել ֆայլ</Button>
                                </Upload>
                                <Button
                                    className='bg-blue-600'
                                    type="primary"
                                    onClick={handleUpload}
                                    disabled={fileList.length === 0}
                                    loading={sendLoading}
                                    style={{marginTop: 16}}
                                >
                                    {sendLoading ? 'Ուղարկվում է' : 'Ուղարկել'}
                                </Button>
                            </Card>
                        </div>
                </Card>
            
                <div className="flex items-center justify-between mb-2 space-x-2" onClick={() => {handleStopSession();setIsOpenP2P(false); setFromP2P(false)}}>
                    
                    <button type="button" 
                            className="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
                        <FontAwesomeIcon icon={faClose}/>
                    </button>
                </div>

        </Row>
    )
}

export default FileUploadPeer
