export const langCheckDevicesStatus = {
    "arm": {
        tr_microphoneIsNotTurnedOn: "Միկրոֆոնը միացված չէ",
        tr_cameraIsNotTurnedOn: "Տեսախցիկը միացված չէ",
        tr_microphoneTesting: "Միկրոֆոնի ստուգում",
        tr_test: "Թեստ",
        tr_cancel: "Չեղարկել",
        tr_audioTesting: "Ձայնի ստուգում",
        tr_cameraCheck: "Տեսախցիկի ստուգում",
    },
    "eng": {
        tr_microphoneIsNotTurnedOn: "Microphone is not turned on",
        tr_cameraIsNotTurnedOn: "Camera is not turned on",
        tr_microphoneTesting: "Testing of microphone",
        tr_test: "Test",
        tr_cancel: "Cancel",
        tr_audioTesting: "Audio testing",
        tr_cameraCheck: "Camera test",
    },
    "fr": {
        tr_microphoneIsNotTurnedOn: "Le microphone n'est pas connecté",
        tr_cameraIsNotTurnedOn: "La caméra n'est pas connectée",
        tr_microphoneTesting: "Vérification du microphone",
        tr_test: "Test",
        tr_cancel: "Annuler",
        tr_audioTesting: "Vérifier le volume",
        tr_cameraCheck: "",
    },
    "arb": {
        tr_microphoneIsNotTurnedOn: "الميكروفون غير متّصل",
        tr_cameraIsNotTurnedOn: "الكاميرا غير متّصلة",
        tr_microphoneTesting: "فحص الميكروفون",
        tr_test: "فحص",
        tr_cancel: "إلغاء",
        tr_audioTesting: "فحص الصوت",
        tr_cameraCheck: "",
    }
}