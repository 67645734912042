export const langGeneral = {
    "arm": {
        tr_users: "Օգտվողներ",
        tr_messages: "Նամակներ",
        tr_newMessage: "Նոր Նամակ",
        tr_myInvitations: "Իմ հրավերները",
        tr_toMe: "Հրավերները ինձ",
        tr_myMails: "Իմ Նամակները",
        tr_groupMails: "Խմբային Նամակ",
    },
    "eng": {
        tr_users: "Users",
        tr_messages: "Messages",
        tr_newMessage: "A new message",
        tr_myInvitations: "My invitations",
        tr_toMe: "Invitations sent to me",
        tr_myMails: "My mails",
        tr_groupMails: "Group Mail",
    },
    "fr": {
        tr_users: "Utilisateurs",
        tr_messages: "Courriers/messages",
        tr_newMessage: "Nouveau courrier/message",
        tr_myInvitations: "Mes invitations",
        tr_toMe: "Invitations adressées à moi",
        tr_myMails: "",
        tr_groupMails: "Messagerie de groupe",
    },
    "arb": {
        tr_users: "المستخدمون",
        tr_messages: "الرسائل",
        tr_newMessage: "رسالة جديدة",
        tr_myInvitations: "الدعوات التي تمّت من قبلي",
        tr_toMe: "الدعوات التي تلقّيتها",
        tr_myMails: "",
        tr_groupMails: " البريد الجماعي",
    }
}