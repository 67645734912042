import React, { useEffect, useRef, useState } from 'react'
import User from '../states/User'
import { observer } from "mobx-react-lite"
import MicButton from '../components/Mic'
import CameraButton from '../components/Camera'
import Invite from '../components/Invite'
import MeetingParticipants from "../components/MeetingParticipants"
import { useStore } from '../store'
import { MeetingChat } from '../components/MeetingChat'
import { axiosInstance, getQueryByParameter } from "../const/axiosInstance"
import { MeetingTime } from '../components/MeetingClock'
import Split from "react-split";
import { socket } from "../const/socket";
import SOCKET from "../const/socketNames"
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faComment, faDesktop, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import PEERConnection from "../utils/WebRTCHandler";
import "./room.css"

const Room = observer(() => {
    const peerInstance = useRef()
    const [currentRoom, setCurrentRoom] = useState({})
    const [displayStream, setDisplayStream] = useState(false)
    const [requestedDate,setRequestedDate] = useState(0)

    const params = useParams()
    const ROOM_ID = params.roomId
    const PURPOSE = getQueryByParameter("purpose")
    const { videoRoomStore, userStore } = useStore()
    const navigate = useNavigate()


    const closeRoomTab = () => {
        window.localStorage.removeItem("existRoom")
    }

    useEffect(() => {
        const date = async () => {
                 const date = await axiosInstance.get("/devices/time")
                 setRequestedDate(Date.now() - new Date(date.data.data))
              }
         date()
     }, [])
 
 
    //  useEffect(() => {
    //      const getTime= async () => {
    //         const date = await axiosInstance.get("/devices/time")
    //         setRequestedDate(date.data.data)
    //      }
    //      const timer = setInterval(getTime, 10000)
    //      if(new Date(currentRoom.start_date ? currentRoom.start_date : currentRoom.created_at) <= new Date(requestedDate)){
    //         clearInterval(timer)
    //      }
    //  }, [])

    let x = 1

        const checkDevices = () => {
        navigator.mediaDevices.enumerateDevices()
        .then(devices => {
        const microphones = devices.filter(d => d.kind === 'audioinput');
        const cameras = devices.filter(d => d.kind === 'videoinput');

           if( microphones.length <=1 && cameras.length <= 1 ){
            if(microphones.length ==1 && cameras.length == 1){
            navigator.mediaDevices.getUserMedia({
                audio: true,
                video: true
              })}
             setTimeout(checkDevices, 5000)
             x++
           }else if(x>1) {
            window.location.reload()
            return
           } else {
            setTimeout(checkDevices, 5000)
            return
           }
        }
        )
        }



    useEffect(() => {  
        checkDevices()
        if(PURPOSE === "Group" && userStore.username === currentRoom.username){
             axiosInstance.get(`/devices/setSecret/${ROOM_ID}`)
        }
    }, [])

    useEffect(() => {
        window.addEventListener("beforeunload", closeRoomTab)
        return () => {
            window.removeEventListener("beforeunload", closeRoomTab)
            peerInstance.current?.destroyConnection();
        }
    }, []);

    useEffect(() => {
        const hasRoom = localStorage.getItem("existRoom")
        if (User.username && !hasRoom) {
            peerInstance.current = new PEERConnection({
                userDetails: {
                    username: User.username,
                    updateInstance: updateFromInstance
                }, roomId: ROOM_ID
            })
            localStorage.setItem("existRoom", true)
        } else {
            navigate("/home")
        }
        videoRoomStore.getVideoRoomByRoomId(ROOM_ID, PURPOSE).then(res => setCurrentRoom(res))
        if(PURPOSE === "Group"){
            videoRoomStore.getMeetingAllParticiapantsById(ROOM_ID)
        }
        return () => {
            localStorage.removeItem("existRoom")
            userStore.setIsBusy(false)
        }
    }, [])

    const updateFromInstance = (key, value) => {
        if (key === 'displayStream') setDisplayStream(value);
    }
    useEffect(() => {
        if (Object.keys(currentRoom).length && PURPOSE === "Group") {
            if (["approved", "declined"].includes(currentRoom.status)) {
                navigate("/home")
                return
            }
            if (userStore.role !== "Guest" && userStore.username !== currentRoom.username &&
                !currentRoom.invited_users.includes(userStore.username)) {
                navigate("/home")
            }
            if (userStore.role === "Guest" && currentRoom.refused_users.includes(userStore.username)) {
                navigate("/home")
            }
        }
    }, [currentRoom])

    useEffect(() => {
        const showChat = document.querySelector("#showChat");
        showChat.addEventListener("click", () => {
            if (document.querySelector(".main__right").style.display === 'none') {
                document.querySelector(".main__right").style.display = "flex";
                document.querySelector(".main__left").style.display = "flex";
            } else {
                document.querySelector(".main__left").style.display = "flex";
                document.querySelector(".main__right").style.display = "none"
                document.querySelector(".main__left").style.flex = "1";
            }
        });
    }, [])

    const handleLeaveCall = () => {
        if (User.username === currentRoom.username) {
            videoRoomStore.finishedMeeting(ROOM_ID)

        }
        if (User.role === "Guest") {
            window.localStorage.removeItem("username")
        }
        socket.emit(SOCKET.MEETING_USER_DISCONNECT, PURPOSE, User.username)
        peerInstance.current.destroyConnection()
        window.close()
    }

    const toggleScreenShare = () => {
        const { reInitializeStream } = peerInstance.current;
        reInitializeStream(false, true, !displayStream ? 'displayMedia' : 'userMedia').then(() => {
            setDisplayStream(!displayStream);
        });
    }


    return (
        <>
            <div className="header flex justify-between">
                <div className="room-name-time">
                    <h3 className="" style={{"font-weight":"bold"}}>{currentRoom.name}</h3>
                    <MeetingTime
                        start_date={currentRoom.start_date ? currentRoom.start_date : currentRoom.created_at} requestedDate={requestedDate}/>
                </div>
                <div></div>
            </div>
            <Split className="main conf-body" sizes={[70, 30]} minSize={[1000, 350]}>
                <div className="main__left">
                    <div className="videos__group overflow-hidden hover:overflow-auto">
                        <div id="video-scroll" className="overflow-hidden hover:overflow-auto"></div>
                        <div id="video-grid" className=""></div>
                    </div>
                    <div className="options conf-footer">
                        <div className="room-options-bar">
                            {
                                <CameraButton peerInstance={peerInstance.current} />
                            }
                            {
                                <MicButton peerInstance={peerInstance.current} />
                            }
                            <div id="showChat" className="options__button">
                                 <img src="/Iconconferancems.png"/>
                            </div>
                            {
                                PURPOSE === "Group" &&
                                <MeetingParticipants room={currentRoom}/>
                            }
                            {PURPOSE === "Group" && userStore.username === currentRoom.username && <div className="options__button">
                                <Invite />
                            </div>}
                            <div className="options__button">
                                {
                                    <img src="/Iconscreen.png" onClick={toggleScreenShare} />

                                }
                            </div>
                            <div
                                className="options__button"
                                onClick={() => handleLeaveCall()}
                            >
                               <img src='/holdconf.png'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main__right conf-chat">
                    <MeetingChat room_id={ROOM_ID} />
                </div>
            </Split>
        </>
    )
}
)

export default Room;
