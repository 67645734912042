export const langPrivatChat = {
    "arm": {
        tr_deleteMessages: "Ջնջել Նամակները",
        tr_deleteMessage: "Ջնջել Նամակը",
        tr_seeMore: "Տեսնել ավելին",
        tr_delete: "Ջնջել",
        tr_correspondence: "Հաղորդագրություն",
        tr_send: "Ուղարկել",
        tr_myMails: "Իմ Նամակները",

    },
    "eng": {
        tr_deleteMessages: "Delete messages",
        tr_deleteMessage: "Delete message",
        tr_seeMore: "See more",
        tr_delete: "Delete",
        tr_correspondence: "Correspondence",
        tr_send: "Send",
        tr_myMails: "My mails",
    },
    "fr": {
        tr_deleteMessages: "Supprimer les courriers",
        tr_deleteMessage: "Supprimer le courrier",
        tr_seeMore: "Voir plus",
        tr_delete: "Supprimer",
        tr_correspondence: "Message",
        tr_send: "Envoyer",
        tr_myMails: "",
    },
    "arb": {
        tr_deleteMessages: "حذف الرسائل",
        tr_deleteMessage: "حذف الرسالة",
        tr_seeMore: "عرض المزيد",
        tr_delete: "حذف",
        tr_correspondence: "رسالة",
        tr_send: "إرسال",
        tr_myMails: "",
    }
}