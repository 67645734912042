import React, {useContext, useState} from "react";
import { langContext } from "../App";
import { langLogin } from "./loginTranslate";
import {Link} from "react-router-dom"
import Lang from "../components/Lang/Lang"
import Logo from "../components/Logo";
import Footer from "../components/Footer"

import "./login.css"
import { axiosInstance } from "../const/axiosInstance";
import { NotificationContainer } from "react-notifications";


const RegisterFirst = ((props) => {
    const lang = useContext(langContext);
    let showBySelected = langLogin[lang]

    let [phone, setPhone] = useState("")
    let [email, setEmail] = useState("")
    let [error, setError] = useState(false)
    const [verCode, setVerCode] = useState("")
    const [emailVerification, setEmailVerification] = useState("register")

    async function sendPinRequest () {
        if(phone.length && phone.length != 11){
                setError("Անհրաժեշտ է ճիշտ լրացնել հեռախոսահամարը")
                return
            }
        if(!email){
                setError("Անհրաժեշտ է լրացնել էլ․ հասցեն")
                return
        }else if(!email.includes("@")){
                setError("Անհրաժեշտ է ճիշտ լրացնել էլ․ հասցեն")
                return
        }else{
	    setEmailVerification("verify")
            let res = await axiosInstance.post("auth/email", {email})
            
        }
    }

    async function sendRequest() {
        let res = await axiosInstance.post("/auth/pin-request",{verCode, phone: `+${phone}`, email})
        if(res.data.status){
            setEmailVerification("Done")
        }else{
            setError("Հաստատման կոդը սխալ է")
        }
    }
       

 

    return (
        <div className="login-page" style={{"height": window.innerHeight}}>
            <Lang change={props.change} value={props.value}/>
            <Logo />
            <div className="background-div">
                {emailVerification === "register" ? <div className="info-div">
                        <h3 className="fontstyle head3" >WORK MEETINGS</h3>                        
                        <h3 className="fontstyle head3" >{showBySelected.tr_confSystem}</h3>
                        <h1 className="fontstyle head1" >Դիմել գրանցման</h1>
                                <>
                                <p className="fontstyle label-p" >Լրացրե՛ք Ձեր էլ․ հասցեն</p>
                                    <div className="info-center">
                                        <div className="info-input-div">
                                            <img src="/Iconpin.png" className="icons-input" />
                                            <input type="email" name="pin_code" placeholder="Էլ․ հասցե (պարտադիր դաշտ)" value={email}
                                                    onChange={(e) => {setError(false);setEmail(e.target.value)}}
                                                    className="info-input" />
                                        </div>
                                    </div>
                                    <p className="fontstyle label-p" >Լրացրե՛ք Ձեր հերախոսահամարը</p>
                                    <div className="info-center">
                                        <div className="info-input-div">
                                            <img src="/Iconpin.png" className="icons-input" />
                                            <div style={{display: "flex"}}>
                                                <p className="info-p">+</p>
                                                <input type="number" name="pin_code" placeholder="Հեռախոսահամար (միջազգային)" value={phone}
                                                        pattern="+[0-9]{11}"
                                                        onChange={(e) => {setError(false);setPhone(e.target.value)}}
                                                        className="info-input" />
                                            </div>
                                        </div>
                                    </div>
                                 
                                    {error ? 
                            <span className="fontstyle" style={{"color":"tomato", fontSize:"14px"}}>{error}</span>
                            :<div style={{"height":"14px"}}></div>}
                                    <div className="info-center">
                                            <button onClick={sendPinRequest}
                                                    className="fontstyle">
                                                    {showBySelected.tr_registr}
                                            </button>
                                    </div>  
                                    <div className="info-center">
                                            <div className="help-and">
                                                <a href="/" target="_blank" rel="noreferrer"
                                                   className="fontstyle">
                                                    {showBySelected.tr_help}
                                                </a>                                      
                                            
                                                <Link to="/"
                                                   className="fontstyle">
                                                    {showBySelected.tr_logIn}
                                                </Link>
                                                
                                            </div>
                                    </div>
                                </>
                                <NotificationContainer/>
                </div> : emailVerification === "verify" ? 
                <div className="info-div">
                    <h3 className="fontstyle head3" >WORK MEETINGS</h3>                        
                    <h3 className="fontstyle head3" >{showBySelected.tr_confSystem}</h3>
                    <h1 className="fontstyle head1" >Դիմել գրանցման</h1>
                    <p className="fontstyle label-p" >Լրացրե՛ք հաստատման գաղտնաբառը։ Այն ուղարկվել է Ձեր էլ․ հասցեին</p>
                        <div className="info-center">
                            <div className="info-input-div">
                                <img src="/Iconpin.png" className="icons-input" />
                                <div style={{display: "flex"}}>
                                    <input type="number" name="pin_code" placeholder="Հաստատման գաղտնաբառ" value={verCode}
                                            pattern="+[0-9]{11}"
                                            onChange={(e) => {setError(false);setVerCode(e.target.value)}}
                                            className="info-input" />
                                </div>
                            </div>
                        </div>
                        {error ? 
                            <span className="fontstyle" style={{"color":"tomato", fontSize:"14px"}}>{error}</span>
                            :<div style={{"height":"14px"}}></div>}
                        <div className="info-center">
                            <button onClick={sendRequest} className="fontstyle">
                                                        Հաստատել
                            </button>    
                        </div>   
                        <div className="info-center">
                            <div className="help-and">
                                <a href="/" target="_blank" rel="noreferrer"
                                    className="fontstyle">
                                    {showBySelected.tr_help}
                                </a>                                      
                            
                                <Link to="/"
                                    className="fontstyle">
                                    {showBySelected.tr_logIn}
                                </Link>
                                
                            </div>
                        </div>
                </div> : <div className="info-div" >
                    <div >
                        <h3 className="fontstyle head3" style={{marginBottom: "20px"}} >WORK MEETINGS</h3>                        
                        <h3 className="fontstyle head3" >{showBySelected.tr_confSystem}</h3>
                    </div>
                    <div>
                        <p className="fontstyle head1" >Ձեր հայտն ընդունված է։</p>
                        <p className="fontstyle head1" >Մեր մասնագետները շուտով կկապվեն Ձեզ հետ։</p>
                    </div>
                    <div className="info-center">
                        <a href = "/">
                            <button onClick={sendPinRequest} className="fontstyle">
                                                        Գլխավոր
                            </button>    
                        </a>
                    </div>  
                </div>
                }
            </div>
        < Footer />
        </div>
    );
})
export default RegisterFirst
