import React from 'react'

export default function MessageTypes(props) {
  return (
    <div className='message-types'>    
        <button className='fontstyles msbtn' onClick={() => {props.chatHandleClick(); props.setMessageTypes(false)}}><img src='/Iconmess.png' className='message-icons'/>{props.tr_message}</button>
        <button className='fontstyles msbtn' onClick={() => {props.chatNewTabHandleClick(); props.setMessageTypes(false)}}><img src="/newtabmess.png" className='message-icons'/>{props.tr_messageInNewPage}</button>        
    </div>
  )
}
