import axios from "axios"
import { BACKEND_URL } from "./Constants";

export const axiosInstance = axios.create({
    baseURL: BACKEND_URL,
    headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': BACKEND_URL },
    withCredentials: true,
  });

export const getQueryByParameter = (queryString) => {
  const queryParams = new window.URLSearchParams(window.location.search)

  return queryParams.get(queryString)
}