import { action, computed, makeObservable, observable, runInAction } from "mobx"
import { axiosInstance } from "../const/axiosInstance"
import { ALERT_MESSAGE_TYPE_DANGER } from "../const/Constants"
import AlertComponent from "../utils/Alert"
import {socket} from "../const/socket"
import SOCKET from "../const/socketNames"


class User {
    username = JSON.parse(localStorage.getItem('username')) ?? ''
    role = ''
    user_settings = {}
    userData = {}
    imagePath= ""
    receivingCall = false
    isBusy: false

    constructor() {
        makeObservable(this, {
            setUsername: action,
            userData: observable.ref,
            imagePath: observable,
            getUsers: action,
            register: action,
            logout: action,
            role: observable,
            getCurrentUser: action,
            user: computed,
            setReceivedCall: action,
            setIsBusy: action
            // setUserImage: action
        })
    }

    setIsBusy(value){
        this.isBusy = value
    }


    async getUsers(search) {
        try {
            const { data: { data: response } } = await axiosInstance.get(`/auth/users`, {
                params: {
                    search: search ? search : ""
                }
            })
            runInAction(() => {
                this.users = response.filter(el => el.username !== this.username)
            })
            

        } catch (error) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
            this.username = null
            localStorage.removeItem('username')
        }
    }
    async login({ username, password }) {
        try {
          const result = await axiosInstance.post("/auth/signin", {
            username,
            password,
          });
          localStorage.setItem("user", username)
          localStorage.setItem("session", result.data.data);
          return result.data;
        } catch (error) {
          AlertComponent(
            ALERT_MESSAGE_TYPE_DANGER,
            error.response.data.message
              ? error.response.data.message
              : error.response.data
          );
        }
      }

      async logout(em) {
        try {
          if (!em) {
            const result = await axiosInstance.get("/auth/logout");
            socket.emit(SOCKET.LOGOUT, this.username);
            if (!result.data.status) {
              AlertComponent(ALERT_MESSAGE_TYPE_DANGER, result.data.message);
              return;
            }
            this.username = null;
            localStorage.removeItem("username");
            localStorage.removeItem("session");
            return result.data;
          } else {
            this.username = null;
            localStorage.removeItem("username");
            localStorage.removeItem("session");
          }
        } catch (error) {
          AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message);
        }
      }
    

    async register({ username, password, pin_code }) {
        try {
            const result = await axiosInstance.post('/auth/signup', { username, password, pin_code })
            return result.data

        } catch (error) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
        }
    }

    async getCurrentUser() {
        try {
            const response = await axiosInstance.get('/auth/user')
            const { username, role, create_meeting, password_deleted, image } = response.data.data
            runInAction(() => {
                this.username = username
                this.role = role
                this.user_settings = {
                    create_meeting,
                    password_deleted
                }
                this.imagePath = image
                this.userData = response.data.data
            })

            return response.data.data
        } catch (error) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
            this.username = null
            localStorage.removeItem('username')
            // window.location = '/login'
        }

    }

    async changeSettings(username, create_room) {
        try {
            const result = await axiosInstance.post('/auth/user/settings', { username, create_room })
            return result.data

        } catch (error) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
        }

    }

    async changePersonalData(data) {
        try {

            const result = await axiosInstance.patch('/auth/user/personal',  data )
            return result.data

        } catch (error) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
        }

    }

    async changeCurrentPassword(old_password, new_password) {
        try {
            const result = await axiosInstance.patch('/auth/user/change-password', { old_password, new_password })
            return result.data
        } catch (error) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
        }
    }

    async uploadProfilePhoto (data) {
        try{
            const {data:{data:response}} = await axiosInstance.post(`/auth/user/images`, data);
            return response.path
            
        }catch(error){
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
            
        }
    }

    async guestUserSignup({username, secret, roomId}) {
        try{
            const result = await axiosInstance.post('/auth/users/guest/signup', { username,  secret, roomId })
            return result.data
        }catch(err) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, err.response.data.message)
        }
    }

    async checkUserIsBusy({username}){
        try{
            const result = await axiosInstance.get(`/devices/user/call/check?username=${username}` )
            return result.data
        }catch(err) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, err.response.data.message)
        }
    }

    async refreshPassword({password}) {
        try{
            const result = await axiosInstance.post('/auth/refresh-password', {
                password
            } )
            return result.data
        }catch(err) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, err.response.data.message)
        }
    }

    async deleteProfilePhoto () {
        try{
            const {data} = await axiosInstance.delete(`/auth/user/image`);
            return data

        }catch(error){
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)

        }
    }



    get user(){
       return  this.username;
    }

    setUserImage( value) {
        this.imagePath = value
    }
    

    setUsername(username) {
        this.username = username
        localStorage.setItem('username', JSON.stringify(this.username))
    }

    setReceivedCall(value) {
        this.receivingCall = value
    }

}


export default new User()