import {action, computed, makeObservable, observable, runInAction} from "mobx"
import AlertComponent from "../utils/Alert"
import {axiosInstance} from "../const/axiosInstance"
import {ALERT_MESSAGE_TYPE_DANGER, MESSAGE_MY, MESSAGE_OTHER} from "../const/Constants"
import User from "./User"

class UserChat {
    messageHistory = []
    username = '';
    roomId = '';
    adminChat = false


    constructor() {
        makeObservable(this,{
            username:observable,
            messageHistory:observable,
            roomId:observable,
            setUsername:action,
            setRoomId:action,
            getMessages:action,
            getRoomId:computed,
            clearUsername:action,
            clearMessages:action,
            setIsRead:action,
            user:computed,
            adminChat: observable,
            setAdminChat: action,
            getAdminUnreadMessages: action

        })
    }

    get messages() {
        return this.messageHistory
    }

    setAdminChat(value){
        this.adminChat = value
    }

    async getMessages({roomId, offset=0, limit=10}) {
        try{
            const {data:{data:response}} = await axiosInstance.get(`/devices/messages/${roomId}?offset=${offset}&limit=${limit}`);
            const messageList =  response.map(el => ({
                _id: el._id,
                message: el.message, type: User.username === el.sender_username ? MESSAGE_MY : MESSAGE_OTHER,
                username: User.username === el.sender_username ? el.receiver_username : el.sender_username,
                is_viewed: el.is_viewed,
                created_at: new Date(el.created_at).toLocaleDateString('en-GB', {day:'2-digit',month:'2-digit',year:'2-digit', hour: 'numeric', hour12: false, minute: 'numeric' })
            })).reverse();
            runInAction(()=>{
                if(offset){
                    this.messageHistory = [...messageList, ...this.messageHistory];
                }else {
                    this.messageHistory = [...messageList]
                }
            })
         }catch(error){
             AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
         }
    }

    async getAllMessages(offset, limit) {
        try{
            const {data:{data:response}} = await axiosInstance.get(`/devices/messages?offset=${offset}&limit=${limit}`);
            const messageList = response.map(el => ({
                _id: el._id,
                username: User.username === el.sender_username ? el.receiver_username : el.sender_username,
                receiver_username: el.receiver_username,
                message: el.message,
                is_viewed: el.is_viewed,
                type: User.username === el.sender_username ? MESSAGE_MY : MESSAGE_OTHER,
                created_at: new Date(el.created_at).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                    hour: 'numeric',
                    hour12: false,
                    minute: 'numeric'
                })
            }))
                .reverse()
            runInAction(()=>{
                if(offset){
                    this.messageHistory = [...messageList, ...this.messageHistory];
                }else {
                    this.messageHistory = [...messageList]
                }
            })

         }catch(error){
             AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
         }
    }

    async getAdminMessages() {
        try{
            const {data:{data:response}} = await axiosInstance.get(`/devices/messages/admin`);
            const messageList = response.map(el => ({
                username: User.username === el.sender_username ? el.receiver_username : el.sender_username,
                receiver_username: el.receiver_username,
                message: el.message,
                is_viewed:el.is_viewed,
                type: User.username === el.sender_username ? MESSAGE_MY : MESSAGE_OTHER,
                created_at: new Date(el.created_at).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit',
                    hour: 'numeric',
                    hour12: false,
                    minute: 'numeric'
                })
            }))
                .reverse()
            runInAction(()=>{
                this.messageHistory = messageList
            })
         }catch(error){
             AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)
         }
    }

    async getAdminUnreadMessages ()  {
        try{
            const {data} = await axiosInstance.get(`/devices/messages/admin/unread`);
            runInAction(()=>{
                if(data.data.count) {
                    this.adminChat = true
                }
            })
        }catch (e) {
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, e.message)
        }
    }

    async uploadMessages(data) {
        try{
            const {data:{data:response}} = await axiosInstance.post(`/devices/files`, data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            return response
            
        }catch(error){
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data)
            return undefined
            
        }
    }
    async deleteMessageRoomById() {
        try{
            const {data} = await axiosInstance.delete(`/devices/messages/${this.roomId}?username=${this.username}`);
            return data

        }catch(error){
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)

        }
    }
    async deleteMessageById(message) {
        try{
            if(message.message.includes("uploadsMS")){
                await axiosInstance.post(`/devices/deleteFile`, {message: message.message})
            }
            const {data} = await axiosInstance.delete(`/devices/message/${message._id}`);
            return data

        }catch(error){
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, error.response.data.message)

        }
    }


    clearMessages() {
        this.messageHistory = []
    }

    addMessage(message,type,date,receiver_username = null, id) {
        this.messageHistory.push({message,type,created_at:date,receiver_username, _id: id})
    }

    setUsername(username)
    {
        this.username = username;
    }

    get user()
    {
        return this.username;
    }

    clearUsername()
    {
        this.username = ''
    }

    // Room ID
    setRoomId(id)
    {
        this.roomId = id
    }

    get getRoomId()
    {
        return this.roomId;
    }

    setIsRead()
    {
        this.messageHistory.filter(el => el.type === 'MY').map(el => el.is_viewed = true)
    }


}

export default new UserChat()