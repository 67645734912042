export const langSideNav = {
    "arm": {
        tr_search: "Որոնում",
        tr_waitingForResponse: "Սպասվում է պատասխան",
        tr_connect: "Միանալ",
        tr_reject: "Հրաժարվել"
    },
    "eng": {
        tr_search: "Search",
        tr_waitingForResponse: "Waiting for a response",
        tr_connect: "Connect",
        tr_reject: "Reject"
    },
    "fr": {
        tr_search: "Recherche",
        tr_waitingForResponse: "En attente de réponse",
        tr_connect: "Connecter",
        tr_reject: "Rejeter l’appel"
    },
    "arb": {
        tr_search: "بحث",
        tr_waitingForResponse: "بانتظار الردّ",
        tr_connect: "الانضمام",
        tr_reject: "عدم الردّ"
    }
}