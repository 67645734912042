import React, {memo, useState} from "react";
import Select from "react-select";


function SelectOption(props) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    // const [inputValue, setInputValue] = useState(false);
    const onMenuOpen = () => setIsMenuOpen(true);
    const onMenuClose = () => setIsMenuOpen(false);

    const handleInputChange = (newValue) => {
        // setInputValue(inputValue);
        return newValue.replace(/\W/g, '');
    };

    return (<Select
            {...props}
            closeMenuOnSelect={false}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            onInputChange={handleInputChange}
        />);
}

export default memo(SelectOption);