export const langAnswerCall = {
    "arm": {
        tr_callingYou: "Զանգահարում է ձեզ",
        tr_answer: "Պատասխանել",
        tr_reject: "Հրաժարվել"
    },
    "eng": {
        tr_callingYou: "Is calling you",
        tr_answer: "Answer",
        tr_reject: "Reject"
    },
    "fr": {
        tr_callingYou: "Vous avez un appel de",
        tr_answer: "Répondre",
        tr_reject: "Rejeter l’appel"
    },
    "arb": {
        tr_callingYou: "يتّصل بك",
        tr_answer: "الردّ",
        tr_reject: "عدم الردّ"
    }
}