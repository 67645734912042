import React from "react";

const Footer = () => {
    

    return (
        <div className="footer">
            <div className="copyright">
               © Mrgelyan, 2022. All Rights Reserved by YCRDI.
             </div>
        </div>
 )
}

export default Footer
