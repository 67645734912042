export const langDeletCantent = {
    "arm": {
        tr_deleteData: "Ջնջել տվյալները"
    },
    "eng": {
        tr_deleteData: "Delete data"
    },
    "fr": {
        tr_deleteData: "Supprimer les données"
    },
    "arb": {
        tr_deleteData: "حذف البيانات"
    }
}