export const langMeetingGuest = {
    "arm": {
        tr_notRegistered: "Օգտանունը գրանցված չէ համակարգում",
        tr_conferanceNotFound: "Վիդեոկոնֆերենսը չի գտնվել",
        tr_connectToVideo: "Միանալ տեսակոնֆերենս կապին",
        tr_userName: "Օգտանուն",
        tr_ifAlreadyLogedIn: "Եթե արդեն մուտք եք գործել համակարգ",
        tr_joinTheMeting: "Միանալ հանդիպմանը",
        tr_confSystem: "ՏԵՍԱՁԱՅՆԱՅԻՆ ԿՈՆՖԵՐԱՆՍ ԿԱՊԻ ՀԱՄԱԿԱՐԳ",
    },
    "eng":{
        tr_notRegistered: "The user name has not been registered in the system",
        tr_conferanceNotFound: "The video conference has not been found",
        tr_connectToVideo: "connect to the video conference",
        tr_userName: "User name",
        tr_ifAlreadyLogedIn: "If you have logged in the system",
        tr_joinTheMeting: "Join the meeting",
        tr_confSystem: "VIDEO CONFERENCE COMMUNICATION SYSTEM",
    },
    "fr": {
        tr_notRegistered: "Aucun nom d’utilisateur enregistré dans le système",
        tr_conferanceNotFound: "Vidéoconférence introuvable",
        tr_connectToVideo: "Rejoindre une conférence vidéo",
        tr_userName: "Nom d’utilisateur",
        tr_ifAlreadyLogedIn: "Si vous vous êtes déjà connecté ",
        tr_joinTheMeting: "Rejoindre la réunion",
        tr_confSystem: "",
    },
    "arb": {
        tr_notRegistered: "اسم المستخدم غير مسجّل في النظام",
        tr_conferanceNotFound: "لم يتمّ العثور على المكالمة الجماعية عبر الفيديو",
        tr_connectToVideo: "الإنضمام إلى المكالمة الجماعيّة عبر الفيديو",
        tr_userName: "اسم المستخدم",
        tr_ifAlreadyLogedIn: "في حال كنت قد قمت بتسجيل الدخول إلى النظام",
        tr_joinTheMeting: "الانضمام إلى الاجتماع",
        tr_confSystem: "",
    }
}