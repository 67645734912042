export const langChangeRoom = {
    "arm": {
        tr_changeData: "Փոփոխել Կոնֆերենսի տվյալները",
        tr_meetingTitle: "Հանդիպման անունը",
        tr_numberOfParticipants: "Հանդիպման մասնակիցների քանակը",
        tr_selectUsers: "Ընտրել օգտվողներին",
        tr_selectTime: "Ընտրեք ձեր հանդիպման ժամը",
        tr_acceptTheChange: "Ընդունել փոփոխությունը",
        tr_participants: "Մասնակիցներ",
        tr_dayAndTime: "Օր և ժամ",
    },
    "eng": {
        tr_changeData: "Change conference data",
        tr_meetingTitle: "Meeting title",
        tr_numberOfParticipants: "Number of participants to the meeting",
        tr_selectUsers: "Select users",
        tr_selectTime: "Select the time of your",
        tr_acceptTheChange: "Accept the change",
        tr_participants: "Participants",
        tr_dayAndTime: "Day and time",
    },
    "fr": {
        tr_changeData: "Modifier les informations sur la conférence",
        tr_meetingTitle: "Titre de la réunion",
        tr_numberOfParticipants: "Nombre de participants à la réunion",
        tr_selectUsers: "Sélectionnez les utilisateurs",
        tr_selectTime: "Sélectionnez l’heure de votre réunion.",
        tr_acceptTheChange: "Accepter la modification",
        tr_participants: "participants",
        tr_dayAndTime: "Jour et heure",
    },
    "arb": {
        tr_changeData: "تعديل بيانات المكالمة الجماعيّة",
        tr_meetingTitle: "اسم الاجتماع",
        tr_numberOfParticipants: "عدد المشاركين في الاجتماع",
        tr_selectUsers: "اختيار المستخدمين",
        tr_selectTime: "اختيار ساعة اجتماعكم",
        tr_acceptTheChange: "قبول التعديل",
        tr_participants: "الحاضرين",
        tr_dayAndTime: "اليوم والوقت",
    }
}