import React from 'react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { langContext } from '../../App'
import { useStore } from '../../store'
import { langUserModal } from './userModalTranslate'

export default function UserModal({setNavbar, setUserModal}) {

  let lang = useContext(langContext)
  let showBySelected = langUserModal[lang]

  const navigate = useNavigate()

  const { userStore} = useStore()


  const logout = async () => {
      await userStore.logout()
      navigate('/')
  }

  return (
    <div className='user-modal'>
        <div className="user-modal-div" onClick={() => {setNavbar("profile"); setUserModal(false)}}><img src='/settings.png'/>{showBySelected.tr_settings}</div>
        <a href={lang === "arm" ? "/armsupport.pdf" : "/engsupport.pdf"} className="user-modal-div" target="_blank" onClick={() => setUserModal(false)}><img src='/help.png'/>{showBySelected.tr_help}</a>
        <div className="user-modal-div" onClick={() => {logout(); setUserModal(false)}}><img src='/logout.png'/>{showBySelected.tr_logOut}</div>

    </div>
  )
}
