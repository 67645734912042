import React, {useEffect, useState, memo} from "react";
import { BACKEND_URL } from "../const/Constants";

const MessageItem = ({ message }) => {
    const [isFile,setIsFile] = useState(false)


    useEffect( () => {
        if (message && message.includes('uploads')) {
            setIsFile(true)
        }else{
            setIsFile(false)
        }
    }, [message]);

    let imageType = message.includes('.jpg') || message.includes('.jpeg') || message.includes('.png') || message.includes('.svg') || message.includes(".gif") || message.includes(".webP")


    return (
        <p className="msg-size">{isFile ? (
            <div className=" file-send-font">
                { imageType ? 
                    <a href={`${BACKEND_URL}${message}`} download target="_blank" className="file-send-box"> 
                        <img src={`${BACKEND_URL}${message}`} alt={`${message.split('uploads')[1]}`} />
                    </a> : 
                    <div className="download-box">
                        <a className="download-box" href={`${BACKEND_URL}${message}`} download target="_blank" onClick={(e) => e.stopPropagation()}>
                            <p className="file-send-box">{message.split('uploads')[1]}</p>
                            <img className="download-img" src="/download.png" />    
                        </a>
                    </div>}
            </div>) : 
            message.includes("http") ? <a href={message} target="_blank">{message}</a> : message}
        </p>
    )
}

export default memo(MessageItem)
