import React, {useEffect, useState, memo, useContext} from "react";
import VideoRoom from "../states/VideoRoom";
import {Menu} from "@headlessui/react";
import AlertComponent from "../utils/Alert";
import {ALERT_MESSAGE_TYPE_DANGER, ALERT_MESSAGE_TYPE_SUCCESS} from "../const/Constants";
import Modal from "../components/modal/CustomModal"
import ChangeRoom from "../components/modal/ChangeRoom";
import {observer} from "mobx-react-lite";
import {ApproveOrCancel} from "../components/modal/ApproveOrCancel";
import {socket} from "../const/socket";
import SOCKET from "../const/socketNames"
import User from "../states/User";
import {getRoomIconByStatus, options, classNames, getClassByStatus, popUp} from "../utils/helpers"
import {useStore} from "../store";
import { langContext } from "../App";
import { langMeetingList } from "./meetingsListTranslate";
import "../pages/homepage.css"

const MeetingsList = observer(({title, requestedDate, searchInput, setSearchInput, groupChatOpened, setGroupChatOpened, makeCon}) => {
    const lang = useContext(langContext);
    let showBySelected = langMeetingList[lang]

    const [modalIsOpen, setIsOpen] = useState(false);
    const [changeMeeting, setChangeMeeting] = useState(false)
    const [decline, setDecline] = useState(false)
    const [deleteMeeting, setDeleteMeeting] = useState(false)
    const [selectedRoom, setSelectedRoom] = useState({})
    const [dateState, setDateState] = useState(0);
    const {userStore, usersStore} = useStore();

    useEffect(() => {
        socket.on(SOCKET.DECLINE_ME_INVITE_MEETING, () => {
            VideoRoom.getMyVideoMeetings()
        })

    }, []);

    useEffect(() => {
        setDateState(requestedDate)
    }, [requestedDate])

    useEffect(() => {
        VideoRoom.getMyVideoMeetings().then(r => r)
    }, [selectedRoom])

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
        setDecline(false);
        setDeleteMeeting(false);
        setChangeMeeting(false)
    }


    const connectMeeting = async (roomId, start_date) => {
        if (new Date(Date.now() - dateState).toISOString() < new Date(new Date(start_date).setMinutes(new Date(start_date).getMinutes() - 10)).toISOString()) {
            const date = new Date(start_date).toString().split(" ")
            AlertComponent(ALERT_MESSAGE_TYPE_DANGER, showBySelected.tr_meetingdate(date[1],date[2],date[4]))
        } else {
            const [myHasBusy] = await Promise.all([userStore.checkUserIsBusy({username: userStore.username})])

            if (!myHasBusy.status) {
                AlertComponent(ALERT_MESSAGE_TYPE_DANGER, showBySelected.tr_alreadyInCall)
                return
            }

            socket.emit(SOCKET.MEETING_STARTED, roomId)
            popUp(`/video-room/${roomId}?purpose=Group`)
        }
    }

    const approveVideoMeetingDecline = async (reasonMessage) => {

        const result = await VideoRoom.declineVideoMeeting(selectedRoom['_id'])
        const status = result.status ? ALERT_MESSAGE_TYPE_SUCCESS : ALERT_MESSAGE_TYPE_DANGER
        AlertComponent(status, result.message)
        socket.emit(SOCKET.APPROVE_OR_DECLINE_MEETING, selectedRoom.invited_users, reasonMessage, User.username)
        setSelectedRoom({})
        closeModal()
    }

    const approveMeetingDelete = async () => {
        const result = await VideoRoom.deleteVideoRoomById({roomId: selectedRoom._id})
        const status = result.status ? ALERT_MESSAGE_TYPE_SUCCESS : ALERT_MESSAGE_TYPE_DANGER
        AlertComponent(status, result.message)
        socket.emit(SOCKET.APPROVE_OR_DECLINE_MEETING, selectedRoom.invited_users)
        if (result.status) {
            await VideoRoom.getMyVideoMeetings()
        }
        setSelectedRoom({})

        closeModal()
    }

    const checkLocaleTime = (roomDate, type) => {
        if (type === 'pending') {
            // const roomTime = new Date(roomDate).toLocaleTimeString('en', {
            //     day:'numeric',
            //     month:'numeric',
            //     year:'numeric',
            //     hour: 'numeric',
            //     hour12: false,
            //     minute: 'numeric'
            // });
            // return dateState.toLocaleTimeString('en', {
            //     day:'numeric',
            //     month:'numeric',
            //     year:'numeric',
            //     hour: 'numeric',
            //     hour12: false,
            //     minute: 'numeric'
            // }) > roomTime;
            return new Date(Date.now() - dateState).toISOString() > roomDate
        } else
            return false;
    }

    return (
        <>
            <div className='listtitle-div'>
                <span className='font-bold fontstyles titeles' style={{"margin-left":"16px"}}>{title}</span>
                {VideoRoom.my_meetings.length ? 
                    <div className='fontstyles' >
                                   
                                   {
                                       userStore.user_settings.create_meeting && <button  className="button" onClick={() => {usersStore.getUsers('');setTimeout(makeCon, 50);setGroupChatOpened(!groupChatOpened); setSearchInput("")}}><img src="/shapegroup.png" style={{"width": "13px", "height":"13px", "margin-right": window.innerWidth < 600 ? "0" :"5px"}}/>{window.innerWidth < 600 ? "" : showBySelected.tr_newConf}</button>
               
                                   }
                    </div> : ""
                }
            </div>
            { window.innerWidth >= 600 ?
                (<div className="list-scroll">
                    <table className="w-full">
                        <thead>
                            <tr>
                                <th className="describtions fontstyles">{showBySelected.tr_name}</th>
                                <th className="describtions fontstyles"><div className="dash"></div>{showBySelected.tr_participant}</th>
                                <th className="describtions fontstyles"><div className="dash"></div>{showBySelected.tr_time}</th>
                                <th className="describtions fontstyles"><div className="dash"></div>{showBySelected.tr_status}</th>
                                <th  className="describtions" style={{"width" :"50px"}}><div className="dash" ></div></th>
                            </tr>
                        </thead>
                        <tbody>
                            {VideoRoom.my_meetings.map((room, idx) => (
                                <Menu key={idx} as="tr" className="fontstyles hover:text-gray-800 hover:bg-gray-100 list-tr">
                                    <td className="list-td">{room.name}</td>
                                    <td className="list-td">{room.invited_users.length + 1}</td>
                                    <td className="list-td">{new Date(room.start_date).toLocaleDateString('en-GB', options)}</td>
                                    <td >
                                        <div className={"status-td " + getClassByStatus(checkLocaleTime(room.start_date, room.status) ? 'warning' : room.status)}>
                                            {(getRoomIconByStatus(checkLocaleTime(room.start_date,room.status) ? 'warning' : room.status))}
                                        </div>
                                    </td>
                                    <Menu.Button as={'td'} className="hover:cursor-pointer ">
                                        <div className="settings-img">
                                            <img src="/Iconsettings.png" />
                                        </div>
                                        <Menu.Items as={'td'} className="list-control-div">
                                            <div className="py-1">
                                                {
                                                    (room.status === "pending" || room.status === "started" ) && 
                                                    <>
                                                        { <Menu.Item>
                                                                <button onClick={() => connectMeeting(room._id, room.start_date)}
                                                                       className="list-controller-btn participate-controller">
                                                                    <img src="/participate.png" />
                                                                    {showBySelected.tr_toParticipate}
                                                                </button>
                                                        </Menu.Item>}
                                                        <hr/>
                                                        <Menu.Item>
                                                            <button onClick={() => {
                                                                    setChangeMeeting(true);
                                                                    setSelectedRoom(room);
                                                                    openModal()
                                                                    }}
                                                                    className="list-controller-btn change-controller">
                                                                <img src="/change.png" />
                                                                {showBySelected.tr_change}
                                                            </button>
                                                        </Menu.Item>
                                                        <hr/>
                                                        <Menu.Item>
                                                                <button onClick={() => {
                                                                        setDecline(true);
                                                                        setSelectedRoom(room);
                                                                        openModal();
                                                                        }} className="list-controller-btn cencel-controller">
                                                                    <img src="/cencelred.png" />
                                                                    {showBySelected.tr_cancel}
                                                                </button>
                                                        </Menu.Item>
                                                    </>
                                                }
                                                <hr/>
                                                <Menu.Item>
                                                        <button onClick={() => {
                                                                setDeleteMeeting(true);
                                                                setSelectedRoom(room);
                                                                openModal()
                                                                }} className="list-controller-btn cencel-controller">
                                                            <img src="/deletered.png" />
                                                            {showBySelected.tr_delete}
                                                        </button>
                                                </Menu.Item>
                                            </div>
                                        </Menu.Items>
                                    </Menu.Button>
                                 </Menu>
                            ))}
                                {(VideoRoom.my_meetings.length === 0) &&
                                <div className="epmty-list">
                                    <img src="/newConf.png" style={{"width":"134px", "height":"95px","margin-left":"18px" }}/>
                                    <div className='fontstyles' >
                                        {
                                            userStore.user_settings.create_meeting && <button  className="button" onClick={() => {usersStore.getUsers('');setTimeout(() => makeCon(), 50);setGroupChatOpened(!groupChatOpened); setSearchInput("")}}><img src="/shapegroup.png" style={{"width": "13px", "height":"13px", "margin-right":"5px"}}/>{showBySelected.tr_newConf}</button>
                                        }
                                     </div>
                                </div>}
                    </tbody>
                </table>
                {
                    changeMeeting &&
                    <Modal
                        isClose={() => closeModal()}
                        isOpen={modalIsOpen}>
                        <ChangeRoom room={selectedRoom} closeModal={closeModal} setIsOpen={setIsOpen}/>
                    </Modal>
                }

                {
                    decline &&
                    <Modal
                        isClose={() => closeModal()}
                        isOpen={modalIsOpen}>

                        <ApproveOrCancel approveHandler={approveVideoMeetingDecline} cancelHandler={closeModal}
                                        title={showBySelected.tr_canclReason}/>
                    </Modal>
                }
                {
                    deleteMeeting &&
                    <Modal
                        isClose={() => closeModal()}
                        isOpen={modalIsOpen}>

                        <ApproveOrCancel approveHandler={approveMeetingDelete} cancelHandler={closeModal}
                                        reason_need={false}/>
                    </Modal>
                }
            </div> ) : 
                (<div>
                    <div className="mob-conf-list-my">           
                    {VideoRoom.my_meetings.map((room, idx) => (
                        <div className="mob-each-conf">
                        <Menu key={idx} as="div"
                        className="fontstyles list-tr ">
                        <Menu.Button as={'div'} className="my-mob-list">
                        <div ><p className="headers">{showBySelected.tr_name}</p><p className="list-td">{room.name}</p></div>
                        <hr className="headers-div"/>
                        <div ><p className="headers">{showBySelected.tr_participant}</p><p className="list-td">{room.invited_users.length + 1}</p></div>
                        <hr className="headers-div"/>
                        <div ><p className="headers">{showBySelected.tr_time}</p><p className="list-td">{new Date(room.start_date).toLocaleDateString('en-GB', options)}</p></div>
                        <hr className="headers-div"/>
                        <div className={"status-td " + getClassByStatus(checkLocaleTime(room.start_date, room.status) ? 'warning' : room.status)}>
                            <p >{(getRoomIconByStatus(checkLocaleTime(room.start_date,room.status) ? 'warning' : room.status))}</p></div>
                                    <Menu.Items as={'td'}
                                    className="list-control-div">
                                        <div className="py-1">
                                        {
                                            (room.status === "pending" || room.status === "started") && <>
                                                <Menu.Item>                                            
                                                    <button onClick={() => connectMeeting(room._id, room.start_date)}
                                                    className="list-controller-btn participate-controller" ><img src="/participate.png" />{showBySelected.tr_toParticipate}</button>
                                                </Menu.Item>
                                                <hr/>
                                                <Menu.Item>
                                    
                                                <button onClick={() => {
                                                    setChangeMeeting(true);
                                                    setSelectedRoom(room);
                                                    openModal()
                                                    }}
                                                    className="list-controller-btn change-controller"><img src="/change.png" />{showBySelected.tr_change}</button>                                           
                                                </Menu.Item>
                                                <hr/>
                                                <Menu.Item>                                            
                                                    <button onClick={() => {
                                                        setDecline(true);
                                                        setSelectedRoom(room);
                                                        openModal();
                                                    }} className="list-controller-btn cencel-controller"><img src="/cencelred.png" />{showBySelected.tr_cancel}</button>                                            
                                                </Menu.Item>
                                            </>
                                            }

                                    <Menu.Item>                                    
                                            <button onClick={() => {
                                                setDeleteMeeting(true);
                                                setSelectedRoom(room);
                                                openModal()
                                            }} className="list-controller-btn cencel-controller"><img src="/deletered.png" />{showBySelected.tr_delete}</button>                                    
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                            </Menu.Button>
                        </Menu>
                        </div>
                    ))}
                    {(VideoRoom.my_meetings.length === 0) &&
                    <div className="epmty-list">
                            <img src="/newConf.png" style={{"width":"134px", "height":"95px","margin-left":"18px" }}/>
                            <div className='fontstyles' >
                                    
                                {
                                    userStore.user_settings.create_meeting && <button  className="button" onClick={() => {usersStore.getUsers('');setTimeout(() => makeCon(), 50);setGroupChatOpened(!groupChatOpened); setSearchInput("")}}><img src="/shapegroup.png" style={{"width": "13px", "height":"13px", "margin-right":"5px"}}/>{showBySelected.tr_newConf}</button>
                    
                                }
                            </div>
                        </div>}
                    </div>
                    
                    {
                    changeMeeting &&
                    <Modal
                        isClose={() => closeModal()}
                        isOpen={modalIsOpen}>
                        <ChangeRoom room={selectedRoom} closeModal={closeModal} setIsOpen={setIsOpen}/>
                    </Modal>
                    }
                    
                    {
                    decline &&
                    <Modal
                        isClose={() => closeModal()}
                        isOpen={modalIsOpen}>
                    
                        <ApproveOrCancel approveHandler={approveVideoMeetingDecline} cancelHandler={closeModal}
                                        title={showBySelected.tr_canclReason}/>
                    </Modal>
                    }
                    {
                    deleteMeeting &&
                    <Modal
                        isClose={() => closeModal()}
                        isOpen={modalIsOpen}>
                    
                        <ApproveOrCancel approveHandler={approveMeetingDelete} cancelHandler={closeModal}
                                        reason_need={false}/>
                    </Modal>
                    }
                </div>)
                }
        </>
        )
   
})

export default memo(MeetingsList)