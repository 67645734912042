export const langApproveOrCancel = {
    "arm": {
        tr_cause: "Պատճառը",
        tr_confirm: "Հաստատել",
        tr_refuse: "Մերժել",
        tr_youSure: "Դուք վստահ ե՞ք, որ ուզում եք ջնջել կոնֆերանսը ցանկից",
        tr_delete: "Ջնջել",
        tr_youWantCancel: "Դուք վստահ ե՞ք, որ ուզում եք չեղարկել կոնֆերանսը"
    },
    "eng": {
        tr_cause: "Cause",
        tr_confirm: "Confirm",
        tr_refuse: "Refuse",
        tr_youSure: "Are you sure you want to delete the conference from the list?",
        tr_delete: "Delete",
        tr_youWantCancel: "Are you sure you want to cancel the conference?"
    },
    "fr": {
        tr_cause: "Motif",
        tr_confirm: "Approuver",
        tr_refuse: "Rejeter",
        tr_youSure: "Voulez-vous vraiment supprimer la conférence de la liste ? ",
        tr_delete: "Supprimer",
        tr_youWantCancel: "Voulez-vous vraiment annuler la conférence? "
    },
    "arb": {
        tr_cause: "السبب",
        tr_confirm: "تأكيد",
        tr_refuse: "رفض",
        tr_youSure: "هل أنت متأكد أنك تريد حذف المؤتمر من القائمة؟",
        tr_delete: "حذف",
        tr_youWantCancel: "هل أنت متأكد أنك تريد إلغاء المؤتمر؟"
    }
}