import { useEffect, useState } from "react";
import {useLocation, Navigate} from "react-router-dom";
import LoadingSpinner from "../components/Loading";
import { ADMIN_ROLE } from "../const/Constants";
import User from "../states/User";

export function RequireAuth({ children }) {
  const [role, setRole] = useState("")

  useEffect(() => {
    User.getCurrentUser().then(res => {res ? setRole(User.role) : window.location = "/"}).catch((e) => {
    })
  }, [])
  const isExist = window.localStorage.getItem("username")
  let location = useLocation();
  if (!role) {
    location.href = "/"
  }
  else if (role === ADMIN_ROLE) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  } else if(isExist) {
    return children;
  }

}

export function AdminAuthenticated({ children }) {
  const [role, setRole] = useState("")

  useEffect(() => {
    User.getCurrentUser().then(res => setRole(User.role))
  }, [])
  let location = useLocation();

  if (!role) {
    <LoadingSpinner />
  } else if (role !== ADMIN_ROLE) {
    return <Navigate to="/home" state={{ from: location }} replace />;
  } else {
    return children;
  }
}
