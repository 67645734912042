import Peer, {DataConnection} from "peerjs";
import {message} from "antd";
import { PEER_HOSTNAME, PEER_PORT, PEER_SECURE, PEER_URL } from "../../const/Constants";

export enum DataType {
    FILE = 'FILE',
    OTHER = 'OTHER'

}
export interface Data {
    dataType: DataType
    file?: Blob
    fileName?: string
    fileType?: string
    message?: string
}

let peer: Peer | undefined
let connectionMap: Map<string, DataConnection> = new Map<string, DataConnection>()

export const PeerConnection = {
    getPeer: () => peer,
    startPeerSession: () => new Promise<string>((resolve, reject) => {
        try {
            let user = "" + localStorage.getItem("user")
            peer = new Peer(user, {host: PEER_HOSTNAME, port: +PEER_PORT, secure: PEER_SECURE, path: PEER_URL})
            peer.on('open', (id) => {
                resolve(id)
            }).on('error', (err) => {
                message.error("Սխալմունք")
            })
        } catch (err) {
            reject(err)
        }
    }),
    closePeerSession: (id) => new Promise<void>((resolve, reject) => {
        try {
            if (peer) {
                peer.destroy()
                peer = undefined
                connectionMap.delete(id)
            }
            resolve()
        } catch (err) {
            reject(err)
        }
    }),
    connectPeer: (id: string) => new Promise<void>((resolve, reject) => {
        if (!peer) {
            reject(new Error(""))
            return
        }
        if (connectionMap.has(id)) {
            reject(new Error(""))
            return
        }
        try {
            let conn = peer.connect(id, {reliable: true})
            if (!conn) {
                reject(new Error(""))
            } else {
                conn.on('open', function() {
                    connectionMap.set(id, conn)
                    resolve()
                }).on('error', function(err) {
                    reject(err)
                })
            }
        } catch (err) {
            reject(err)
        }
    }),
    onIncomingConnection: (callback: (conn: DataConnection) => void) => {
        peer?.on('connection', function (conn) {
            connectionMap.set(conn.peer, conn)
            callback(conn)
        });
    },
    onConnectionDisconnected: (id: string, callback: () => void) => {
        if (!peer) {
            throw new Error("")
        }
        if (!connectionMap.has(id)) {
            throw new Error("")
        }
        let conn = connectionMap.get(id);
        if (conn) {
            conn.on('close', function () {
                connectionMap.delete(id)
                callback()
            });
        }
    },
    sendConnection: (id: string, data: Data): Promise<void> => new Promise((resolve, reject) => {
        if (!connectionMap.has(id)) {
            reject(new Error(""))
        }
        try {
            let conn = connectionMap.get(id);
            if (conn) {
                conn.send(data)
            }
        } catch (err) {
            reject(err)
        }
        resolve()
    }),
    onConnectionReceiveData: (id: string, callback: (f: Data) => void) => {
        if (!peer) {
            throw new Error("")
        }
        if (!connectionMap.has(id)) {
            throw new Error("")
        }
        let conn = connectionMap.get(id)
        if (conn) {
            conn.on('data', function (receivedData) {
                let data = receivedData as Data
                callback(data)
            })
        }
    }

}