import React, {useState, useMemo, useContext} from "react";
import {useEffect} from "react";
import {BACKEND_URL} from "../const/Constants";
import User from "../states/User";
import VideoRoom from "../states/VideoRoom";
import FileUpload from "./FileUpload";
import SOCKET from "../const/socketNames"
import { useStore } from "../store";
import {socket} from "../const/socket";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import { langContext } from "../App";
import { langMeetingChat } from "./meetingChatTranslate";

export const MeetingChat = ({ room_id}) => {
    const lang = useContext(langContext);
    let showBySelected = langMeetingChat[lang]

    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [messages, setMessages] = useState([])
    const [bigFile, setBigFile] = useState(false)
    const {videoRoomStore} = useStore()


    const sendMessage = () => {

        if (message.length !== 0) {
            socket.emit(SOCKET.MEETING_CHAT_SEND_MESSAGE, message);
            setMessage("")
            setTimeout(function () {
                scrollDown()
            }, 100)
        }

    }
    function scrollDown() {   document.getElementsByClassName('main__chat_window')[0].scrollTop = document.getElementsByClassName('main__chat_window')[0].scrollHeight}

    useEffect(() => {
        VideoRoom.getMeetingMessagesByRoomId(room_id).then(res => setMessages(res))
        let otherTimer

        socket.on(SOCKET.MEETING_CHAT_GET_MESSAGE, async  (message, userName) => {
           await setMessages((prevstate) => ([...prevstate, {message, username: userName}]))
            otherTimer =  setTimeout( () => scrollDown(), 1000)
        })
       const timer  = setTimeout( () =>
            scrollDown()
        , 1000)

        return () => {
            socket.off(SOCKET.MEETING_CHAT_GET_MESSAGE)
            clearTimeout(timer)
            if(otherTimer) {
                clearTimeout(otherTimer)
            }
        }
    }, [])

    const messageList = useMemo(() => messages.map((el, ix) =>
        (<div className={el.username === User.username ? "conf-message-div my-msg-chat-div" : "conf-message-div"} key={ix}>
            <b>
                <span className="conf-chat-name"> {el.username === User.username ? "me" : videoRoomStore.getFullNameByUserName(el.username)}</span> 
            </b>
            <span  className={el.username === User.username ? "bg-my-msg break-words" : "bg-others-msg break-words"}>{el.message.includes("uploads") ? (
                <a href={`${BACKEND_URL}${el.message}`} download target="_blank">
                    <img src={`${BACKEND_URL}${el.message}`} alt={`${el.message.split('uploads')[1]}`}/>
                </a>) :  el.message.includes("http") ? <a href={el.message} target="_blank">{el.message}</a> : el.message}</span>
        </div>)), [messages])

    return (
        <>
            <div className="room-chat-main">
                <div className="main__chat_window overflow-hidden hover:overflow-y-scroll room-chat-body">
                    <div id="group-messages" className="messages" >
                        {messageList}
                     </div>
                </div>
                <div className="chat-send-hole-div">
                    <div className="chat-send-div">
                        <div className="conf-input-upload">
                            <FileUpload  setField={setMessage} uploadFile={VideoRoom.uploadMessages} setLoading={setLoading} purpose={true}>
                                <img className="chat-upload-icon" src="/Iconfileupload.png" />
                            </FileUpload>
                            <input id="chat_message" type="text"
                                onChange={(e) => message.length < 4000 ? setMessage(e.target.value.trimStart()) : ""}
                                onKeyDown={(event) => event.key === 'Enter' ? sendMessage() : ''}
                                autoComplete='off'
                                value={message}
                                className="conf-input"
                                placeholder={bigFile? "Ֆայլը չպետք է գերազանցի 5GB-ը" : loading ? "Բեռնվում է..." : showBySelected.tr_correspondence}/>
                        </div>
                        <div id="send" className="conf-chat-send-btn">
                            <img src="/sendmsg.png" aria-hidden="true" disabled={loading} onClick={sendMessage}/>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}