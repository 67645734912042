import React, {useContext, useState} from 'react';
import CustomModal from "../../../components/modal/CustomModal";
import DeleteByDate from "../../../components/modal/DeleteByDate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import {useStore} from "../../../store";
import { langContext } from '../../../App';
import { langDeletCantent } from './deleteContentTranslate';

const DeleteContent = ({status, startdate, enddate, type}) => {
    const lang = useContext(langContext);
    let showBySelected = langDeletCantent[lang]

    const [openModal, setOpenModal] = useState(false)
    const {adminStore} = useStore()

    const closeModal = () => {
        setOpenModal(false)
        if(status === "user_logs"){
            adminStore.LogsByUser(startdate, enddate)
        }else if (status === "message_logs") {
            adminStore.getMessagesLogs(startdate, enddate, type)
        }
    }

    const handleClick = () => {
      setOpenModal(true)
    }


    return (
        <>

            <FontAwesomeIcon icon={faTrash} className="fa fa-trash text-white-400 ml-4 cursor-pointer" aria-hidden="true" onClick={handleClick}></FontAwesomeIcon>

                <CustomModal isClose={closeModal} isOpen={openModal}>
                    <div className="flex items-center justify-between space-x-2">
                        <h2 className="text-center	"> {showBySelected.tr_deleteData}</h2>

                        <button type="button" onClick={() => closeModal()}
                                className="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
                            <FontAwesomeIcon icon={faWindowClose} className="fa fa-window-close" aria-hidden="true"/>
                        </button>
                    </div>
                    <DeleteByDate status={status} closeModal={closeModal}/>

                </CustomModal>



        </>
    );
};

export default DeleteContent;